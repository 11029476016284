import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress/nprogress'
import store from '@state/store'
import { ElNotification } from 'element-plus'
import i18n from '@src/locale/index'
import { isAuthorized, siteClearance } from '@utils/clearanceHelpers'
import routes from './routes'

const { t } = i18n.global

// Hide Nprogress spinner
NProgress.configure({ showSpinner: false })

const router = createRouter({
  routes,
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // Detect change of site, remove cached teams of previous site
  if (
    routeTo?.params?.personnelDivision &&
    localStorage.getItem('personnelDivision') !==
      routeTo.params.personnelDivision
  ) {
    localStorage.removeItem('teams')
    localStorage.setItem('personnelDivision', routeTo.params.personnelDivision)
  }
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start()
  }

  const actorRoles = store.state.auth.actorRoles
  checkIdentityMatchingDataValidation(routeTo, routeFrom, next, actorRoles)
  checkAuthorization(routeTo, routeFrom, next, actorRoles)
})

// Check matching data Eplanning-Identity Access
function checkIdentityMatchingDataValidation(
  routeTo,
  routeFrom,
  next,
  actorRoles,
) {
  const identityAndEplanningDataToMatchingValidation =
    getIdentityAndEplanningDataToMatchingValidation()

  if (
    process.env.NODE_ENV !== 'development' &&
    routeTo.name !== 'identityDataDoNotMatchEplanning' &&
    routeTo.name !== 'logout' &&
    identityMatchingDataValidationFails(
      identityAndEplanningDataToMatchingValidation,
      actorRoles,
    )
  ) {
    return next({
      path: '/identityDataDoNotMatchEplanning',
      query: identityAndEplanningDataToMatchingValidation,
    })
  }
}

// Checks actor is authorized to visit route
function checkAuthorization(routeTo, routeFrom, next, actorRoles) {
  const { authorize } = routeTo.meta
  if (!authorize) return next()
  if (
    authorize.length &&
    actorRoles &&
    !authorize.some((element) =>
      actorRoles.some((e) => e.role.code === element),
    )
  ) {
    // role not authorized so redirect to home page
    redirectToHome()
  }

  function redirectToHome() {
    ElNotification({
      title: t('message.errorTitle'),
      message: t('message.unauthorizedRoute'),
      type: 'error',
      offset: 50,
    })
    return next({ path: '/', query: { redirectFrom: routeTo.fullPath } })
  }

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Validate the local user token...
    return store
      .dispatch('auth/validate')
      .then((validUser) => {
        // Then continue if the token still represents a valid user,
        // otherwise redirect to login.
        validUser ? next() : redirectToLogin()
      })
      .catch((e) => console.warn(e))
  }
  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'loginFedId', query: { redirectFrom: routeTo.fullPath } })
  }
}

function getIdentityAndEplanningDataToMatchingValidation() {
  return {
    identityAccessPositionCode: store.state.auth?.identityAccessPositionCode,
    identityAccessPositionName: store.state.auth?.identityAccessPositionName,
    eplanning2PositionCode: store.state.auth?.eplanning2PositionCode,
    eplanning2PositionName: store.state.auth?.eplanning2PositionName,
    identityAccessUsername: store.state.auth?.identityAccessUsername,
    eplanning2Username: store.state.auth?.eplanning2Username,
  }
}

function rolesOutOfIdentityAccess(actorRoles) {
  return (
    isAuthorized(actorRoles, siteClearance.admin) ||
    isAuthorized(actorRoles, siteClearance.directorCoach) ||
    isAuthorized(actorRoles, siteClearance.multicenterLeader)
  )
}

function identityMatchingDataValidationFails(
  identityAndEplanningDataToMatchingValidation,
  actorRoles,
) {
  const notAdmin = actorRoles && !rolesOutOfIdentityAccess(actorRoles)

  const positionOrUsernameDoNotMatch =
    identityAndEplanningDataToMatchingValidation.eplanning2PositionCode &&
    identityAndEplanningDataToMatchingValidation.identityAccessPositionCode !==
      identityAndEplanningDataToMatchingValidation.eplanning2PositionCode

  if (
    identityAndEplanningDataToMatchingValidation.identityAccessPositionCode ===
      'HOTE.VENTE' &&
    (identityAndEplanningDataToMatchingValidation.eplanning2PositionCode ===
      'VDS.SPT' ||
      identityAndEplanningDataToMatchingValidation.eplanning2PositionCode ===
        'HOT.CAI.ACC')
  ) {
    return false
  }

  return notAdmin && positionOrUsernameDoNotMatch
}

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
