import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    pendingSapConversionProcesses: [],
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_PENDING_SAP_CONVERSION_PROCESSES(
    state,
    pendingSapConversionProcesses,
  ) {
    state.pendingSapConversionProcesses = pendingSapConversionProcesses
  },
  RESET_CONVERSION_MANAGER_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  // SAP PENDING CONVERSION PROCESSES
  // GET
  async fetchPendingSapConversionProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_SAP_CONVERSION_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/conversion.updateSapProcess${queryString}`,
      )
      store.commit('UPDATE_PENDING_SAP_CONVERSION_PROCESSES', res.data.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /es/api/v2/contractManager/conversion.updateSapProcess' +
          queryString,
      )
    }
  },

  // SAP CONVERSION RECORDS
  // GET
  async fetchSapConversionRecords(store, processUuid) {
    const queryString =
      processUuid.length > 0
        ? `?filter[conversionProcessesUuid]=${processUuid}`
        : ''
    try {
      return await axios.get(
        `${BASE}/es/api/v2/contractManager/conversion.updateSapProcess/downloadList${queryString}`,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/conversion.updateSapProcess/downloadList${queryString}`,
      )
    }
  },
  // NEW CONVERSION
  // POST
  async startConversionProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversion.startProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversion.startProcess.`,
      )
    }
  },

  // EDIT CONVERSION
  // POST
  async editConversionProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversion.edit`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversion.edit.`,
      )
    }
  },
  async startConversionProcessToCdiFd(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversion.startProcessToCdiFd`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversion.startProcessToCdiFd`,
      )
    }
  },
  // EDIT CDI DF CONVERSION
  // POST
  async editConversionProcessCdiDfAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversionCdiDf.edit`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversionCdiDf.edit`,
      )
    }
  },

  // UPDATE MANAGED CONVERSION PROCESSES
  // POST
  async updateManagedConversions(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversion.updateSapProcess.management`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversion.updateSapProcess.management.`,
      )
    }
  },

  // START CDI 90 DAYS CONVERSION
  // POST
  async startConversionProcessToCdiWeekend90Days(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversionCDIWeekend90Days.start`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversionCDIWeekend90Days.start`,
      )
    }
  },

  // EDIT CDI 90 DAYS CONVERSION
  // POST
  async editConversionProcessToCdiWeekend90Days(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversionCDIWeekend90Days.edit`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversionCDIWeekend90Days.edit`,
      )
    }
  },

  // SIGN ON PAPER CDI 90 DAYS CONVERSION
  // POST
  async signOnPaperConversionCDI90DaysAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversionCDIWeekend90Days.signOnPaper`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversionCDIWeekend90Days.signOnPaper`,
      )
    }
  },
}
