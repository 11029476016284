import esMessages from './Dictionaries/Spanish/messages.json'
import esExceptions from './Dictionaries/Spanish/exceptions.json'
import enMessages from './Dictionaries/English/messages.json'
import enExceptions from './Dictionaries/English/exceptions.json'
import esViewDescriptions from './Dictionaries/Spanish/viewDescriptions.json'
import enViewDescriptions from './Dictionaries/English/viewDescriptions.json'

const messages = {
  es: { ...esMessages.es, ...esExceptions.es, ...esViewDescriptions.es },
  fr: { ...enMessages.en, ...enExceptions.en, ...enViewDescriptions.en },
  en: { ...enMessages.en, ...enExceptions.en, ...enViewDescriptions.en },
  it: { ...enMessages.en, ...enExceptions.en, ...enViewDescriptions.en },
}

export default messages
