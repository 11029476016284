import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL
const defaultActiveForceDataUpdate = { status: null, date: null, message: null, type: null }

const getDefaultState = () => {
  return {
    activeForceDataUpdate: defaultActiveForceDataUpdate,
    forceDataUpdate: null,
    forceDataUpdates: [],
  }
}

export const state = getDefaultState()

export const mutations = {
  UPDATE_ACTIVE_FORCE_DATA_UPDATE(state, status) {
    state.activeForceDataUpdate = status
  },
  UPDATE_FORCE_DATA_UPDATES(state, forceDataUpdates) {
    state.forceDataUpdates = forceDataUpdates
  },
  UPDATE_FORCE_DATA_UPDATE(state, status) {
    state.forceDataUpdate = status
  },
}

export const actions = {
  async fetchActiveForceDataUpdate(store) {
    try {
      store.commit('UPDATE_ACTIVE_FORCE_DATA_UPDATE', defaultActiveForceDataUpdate)
      const res = await axios.get(
        `${BASE}/es/api/v2/forceDataUpdates/activeForceDataUpdateStatus`,
      )
      store.commit('UPDATE_ACTIVE_FORCE_DATA_UPDATE', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /es/api/v2/forceDataUpdates/activeForceDataUpdateStatus',
      )
    }
  },
  async fetchForceDataUpdate(store, uuid) {
    try {
      store.commit('UPDATE_FORCE_DATA_UPDATE', null)
      const res = await axios.get(
          `${BASE}/es/api/v2/forceDataUpdates/${uuid}`,
      )
      store.commit('UPDATE_FORCE_DATA_UPDATE', res.data)
    } catch (error) {
      throw new Error(
          `API Error occurred in /es/api/v2/forceDataUpdates/${uuid}.`,
      )
    }
  },
  async fetchForceDataUpdates(store) {
    try {
      store.commit('UPDATE_FORCE_DATA_UPDATES', [])
      const res = await axios.get(`${BASE}/es/api/v2/forceDataUpdates`)
      store.commit('UPDATE_FORCE_DATA_UPDATES', res.data)
    } catch (error) {
      throw new Error(
          `API Error occurred in /es/api/v2/forceDataUpdates.${error}`,
      )
    }
  },
  async forceDataUpdateCreate({ store }, payload) {
    try {
      return await axios.post(
          `${BASE}/es/api/v2/forceDataUpdates/create.forceDataUpdate`,
          payload,
      )
    } catch (e) {
      throw Error(
          `API Error occurred at /es/api/v2/forceDataUpdates/create.forceDataUpdate`,
      )
    }
  },
  async forceDataUpdateUpdate({ store }, payload) {
    try {
      return await axios.post(
          `${BASE}/es/api/v2/forceDataUpdates/edit.forceDataUpdate`,
          payload,
      )
    } catch (e) {
      throw Error(
          `API Error occurred at /es/api/v2/forceDataUpdates/edit.forceDataUpdate`,
      )
    }
  },
  async forceDataUpdateDelete({ store }, payload) {
    try {
      return await axios.post(
          `${BASE}/es/api/v2/forceDataUpdates/delete.forceDataUpdate`,
          payload,
      )
    } catch (e) {
      throw Error(
          `API Error occurred at /es/api/v2/forceDataUpdates/delete.forceDataUpdate`,
      )
    }
  },
}
