import { siteClearance } from '@utils/clearanceHelpers'

export default [
  /// PLANNING TEMPLATE
  {
    path: '/es/:personnelDivision/planningTemplate',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'planning-template-team-selection',
        component: () =>
          import(
            '@views/planning/planning-template/planning-template-team-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid',
        name: 'planning-template',
        component: () =>
          import('@views/planning/planning-template/planning-template.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////
  // PLANNING TEMPLATE MANAGEMENT
  // //////////
  {
    path: '/es/:personnelDivision/planningTemplateManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'planning-template-management-selection',
        component: () =>
          import(
            '@views/planning/planning-template/planning-template-management-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid/:date',
        name: 'planning-template-management',
        component: () =>
          import(
            '@views/planning/planning-template/planning-template-management.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////////////////////////
  // // TEAM WEEK PLANNING MANAGER
  // //////////////////////////////
  {
    path: '/es/:personnelDivision/planning',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'team-week-selection',
        component: () =>
          import(
            '@/src/router/views/planning/team-week-planning/team-week-selection-manager.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid/:weekStartDate',
        name: 'team-week-planning-manager',
        component: () =>
          import(
            '@/src/router/views/planning/team-week-planning/team-week-planning-manager.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // ///////////////////////////////////
  // // TEAM WEEK PLANNING COLLABORATOR
  // ///////////////////////////////////
  {
    path: '/es/:personnelDivision/planning/collaborator/:teamUuid/:weekStartDate',
    name: 'team-week-planning-collaborator',
    component: () =>
      import(
        '@/src/router/views/planning/team-week-planning/team-week-planning-collaborator.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  // //////////////////////////////
  // // COLLABORATOR MONTH PLANNING
  // //////////////////////////////
  {
    path: '/es/:personnelDivision/planning/monthPlanning',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'collaborator-month-selection',
        component: () =>
          import(
            '@/src/router/views/planning/collaborator-month-planning/collaborator-month-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
      {
        path: ':collaboratorUuid/:monthStartDate',
        name: 'collaborator-month-planning',
        component: () =>
          import(
            '@/src/router/views/planning/collaborator-month-planning/collaborator-month-planning.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
    ],
  },
  // ///////////////////////////////////
  // // COLLABORATOR MONTH PLANNING COLLABORATOR
  // ///////////////////////////////////
  {
    path: '/es/:personnelDivision/planning/collaboratorMonthPlanning/:collaboratorUuid/:monthStartDate',
    name: 'collaborator-month-planning-collaborator',
    component: () =>
      import(
        '@/src/router/views/planning/collaborator-month-planning/collaborator-month-planning-own.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  // ///////////////////////////////////
  // // COLLABORATOR MONTH PLANNING PRINT
  // ///////////////////////////////////
  {
    path: '/es/:personnelDivision/planning/printMonthPlanning/:collaboratorUuid/:monthStartDate',
    name: 'collaborator-month-planning-print',
    component: () =>
      import(
        '@/src/router/views/planning/collaborator-month-planning/collaborator-month-planning-print.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  // //////////
  // GLOBAL PLANNING MANAGEMENT
  // //////////
  {
    path: '/es/:personnelDivision/globalPlanning',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'global-planning-selection',
        component: () =>
          import(
            '@views/planning/global-planning/global-planning-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
      {
        path: ':date/:fictitious?/:hourTypology?',
        name: 'global-planning',
        component: () =>
          import('@views/planning/global-planning/global-planning.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
            ...siteClearance.responsibleCollaborator,
          ],
        },
      },
    ],
  },
  // /////////////
  // HOUR TYPOLOGY
  // /////////////
  {
    path: '/hourTypology',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'hour-typology',
        component: () =>
          import('@views/planning/hour-typology/hour-typology.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: 'create',
        name: 'hour-typology-create',
        component: () =>
          import('@views/planning/hour-typology/hour-typology-create.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':uuid',
        component: () => import('@views/empty-router-view.vue'),

        children: [
          {
            path: '',
            name: 'hour-typology-update',
            component: () =>
              import('@views/planning/hour-typology/hour-typology-update.vue'),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
      {
        path: 'hourParent/',
        component: () => import('@views/empty-router-view.vue'),

        children: [
          {
            path: 'create',
            name: 'hour-parent-create',
            component: () =>
              import('@views/planning/hour-typology/hour-parent-create.vue'),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
          {
            path: ':uuid',
            component: () => import('@views/empty-router-view.vue'),

            children: [
              {
                path: '',
                name: 'hour-parent-update',
                component: () =>
                  import(
                    '@views/planning/hour-typology/hour-parent-update.vue'
                  ),
                meta: {
                  authRequired: true,
                  authorize: siteClearance.admin,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  // //////////////
  // PLANNING RULE
  // //////////////
  {
    path: '/planningRule',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'planning-rule-list',
        component: () =>
          import('@views/planning/planning-rule/planning-rule-list.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: 'create',
        name: 'planning-rule-create',
        component: () =>
          import('@views/planning/planning-rule/planning-rule-create.vue'),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':uuid',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'planning-rule-update',
            component: () =>
              import('@views/planning/planning-rule/planning-rule-update.vue'),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },
  // /////////////
  // GROUPING TYPE
  // /////////////
  {
    path: '/groupingCexReport',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'grouping-cex-report-list',
        component: () =>
          import(
            '@views/planning/grouping-cex-report/grouping-cex-report-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: 'create',
        name: 'grouping-cex-report-create',
        component: () =>
          import(
            '@views/planning/grouping-cex-report/grouping-cex-report-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: siteClearance.admin,
        },
      },
      {
        path: ':uuid',
        component: () => import('@views/empty-router-view.vue'),
        children: [
          {
            path: '',
            name: 'grouping-cex-report-update',
            component: () =>
              import(
                '@views/planning/grouping-cex-report/grouping-cex-report-update.vue'
              ),
            meta: {
              authRequired: true,
              authorize: siteClearance.admin,
            },
          },
        ],
      },
    ],
  },
  // //////////////////
  // SITE CEX MANAGEMENT
  // /////////////////
  {
    path: '/es/:personnelDivision/cexManagement',
    name: 'site-cex-management',
    component: () =>
      import(
        '@/src/router/views/planning/cex-management/site-cex-management.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
      ],
    },
  },
  // //////////////////
  // CEX SUMMARY VIEW
  // /////////////////
  {
    path: '/es/:personnelDivision/cexSummary',
    name: 'cex-summary-view',
    component: () =>
      import('@/src/router/views/planning/cex-management/cex-summary-view.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // //////////////////
  // SITE HOLIDAY MANAGEMENT
  // /////////////////
  {
    path: '/es/:personnelDivision/holidays',
    name: 'site-holidays-select-year',
    component: () =>
      import('@router/views/admin/holidays/site-holidays-select-year.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/holidays/:year',
    name: 'site-holidays-list',
    component: () =>
      import('@router/views/admin/holidays/site-holidays-list.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/holidays/:year/new',
    name: 'site-holidays-create',
    component: () =>
      import('@router/views/admin/holidays/site-holidays-create.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/holidays/:year/:uuid/:isEphemeris?',
    name: 'site-holidays-update',
    component: () =>
      import('@router/views/admin/holidays/site-holidays-update.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
      ],
    },
  },
  // //////////////////
  // HOLIDAY UPLOAD
  // /////////////////
  {
    path: '/es/uploadHolidays',
    name: 'upload-holidays',
    component: () => import('@router/views/admin/holidays/upload-holidays.vue'),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.admin],
    },
  },

  // //////////////////
  // WEEKLY NOTIFICATION
  // /////////////////
  {
    path: '/es/weeklyNotifications/:personnelDivision',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'weekly-selection',
        component: () =>
          import(
            '@router/views/planning/weekly-notifications/weekly-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid?/:year/:quarter/:collaboratorName?',
        name: 'weekly-notifications',
        component: () =>
          import(
            '@/src/router/views/planning/weekly-notifications/weekly-notifications-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'collaborator/:collaboratorUuid/:year/:week/signDocument/:weeklyNotificationUuids*',
        name: 'weekly-notifications-signed',
        component: () =>
          import(
            '@/src/router/views/planning/weekly-notifications/weekly-notifications-signed/weekly-notifications-signed.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },

  // //////////////////
  // QUARTERLY NOTIFICATION
  // /////////////////
  {
    path: '/es/quarterlyNotifications/:personnelDivision',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'quarter-selection',
        component: () =>
          import(
            '@router/views/planning/quarterly-notifications/quarter-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':year/:quarter',
        name: 'quarterly-notifications-site',
        component: () =>
          import(
            '@/src/router/views/planning/quarterly-notifications/quarterly-notifications-list-site.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid/:year/:quarter/:collaboratorName?',
        name: 'quarterly-notifications-team',
        component: () =>
          import(
            '@/src/router/views/planning/quarterly-notifications/quarterly-notifications-list-team.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'collaborator/:collaboratorUuid/:year/:quarter',
        name: 'quarterly-notifications-collaborator',
        component: () =>
          import(
            '@/src/router/views/planning/quarterly-notifications/quarterly-notifications-collaborator/quarterly-notifications-collaborator.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.collaborator,
          ],
        },
      },
      {
        path: 'collaborator/:collaboratorUuid/:year/:quarter/signDocument',
        name: 'quarterly-notifications-signed',
        component: () =>
          import(
            '@/src/router/views/planning/quarterly-notifications/quarterly-notifications-signed/quarterly-notifications-signed.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////////////
  // COLLABORATOR QUARTERLY NOTIFICATION
  // /////////////////
  {
    path: '/es/:personnelDivision/quarterlyNotifications',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'collaborator-quarterly-notifications-selection',
        component: () =>
          import(
            '@router/views/planning/quarterly-notifications/pending-quarterly-notifications-collaborator/pending-quarterly-notifications-collaborator.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.collaborator],
        },
      },
      {
        path: ':collaboratorUuid/:year/:quarter',
        name: 'collaborator-quarterly-notifications',
        component: () =>
          import(
            '@/src/router/views/planning/quarterly-notifications/quarterly-notifications-collaborator/quarterly-notifications-collaborator.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.collaborator],
        },
      },
    ],
  },
  // //////////////////
  // COLLABORATOR PENDING WEEKLY NOTIFICATION
  // /////////////////
  {
    path: '/es/:personnelDivision/weeklyNotificationsList',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'collaborator-pending-weekly-notifications-list',
        component: () =>
          import(
            '@router/views/planning/weekly-notifications/pending-weekly-notifications-collaborator/pending-weekly-notifications-collaborator.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.collaborator],
        },
      },
    ],
  },
  {
    path: '/es/:personnelDivision/weeklyNotifications/:planningNotificationGroupsUuids*',
    name: 'collaborator-pending-weekly-notification-modifications-differences',
    component: () =>
      import(
        '@router/views/planning/weekly-notifications/pending-weekly-notification-modifications-collaborator/pending-weekly-notification-modifications-collaborator-differences.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [...siteClearance.collaborator],
    },
  },
  // /////////////////////
  // // COPY PLANNING
  // /////////////////////
  {
    path: '/es/:personnelDivision/copyPlanning',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'copy-planning-team-week-selection',
        component: () =>
          import('@views/planning/copy-planning/team-week-selection.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid/:weekStartDate',
        name: 'copy-planning',
        component: () =>
          import('@views/planning/copy-planning/copy-planning.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////
  // REBILLING
  // //////////
  {
    path: '/es/:personnelDivision/rebilling',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'rebilling-date-selection',
        component: () =>
          import(
            '@router/views/accounting/rebilling/rebilling-date-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid?/:year/:month',
        name: 'rebilling-manager',
        component: () =>
          import('@router/views/accounting/rebilling/rebilling-manager.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'new',
        name: 'rebilling-create',
        component: () =>
          import('@router/views/accounting/rebilling/rebilling-create.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  {
    path: '/rebillingManagementHr',
    name: 'rebilling-management-hr',
    component: () =>
      import('@views/accounting/rebilling/rebilling-management-hr.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // ///////////
  // PAYROLL PAYMENT
  // //////////
  {
    path: '/payrollPayment',
    name: 'payroll-payment',
    component: () =>
      import('@views/accounting/payroll-payment/payroll-payment.vue'),
    meta: {
      authRequired: true,
      authorize: siteClearance.admin,
    },
  },
  // //////////
  // POLYVALENCE MANAGER
  // //////////
  {
    path: '/es/:personnelDivision/polyvalence',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'polyvalence-date-selection',
        component: () =>
          import(
            '@router/views/accounting/polyvalence/polyvalence-date-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid?/:startDate/:endDate',
        name: 'polyvalence-manager',
        component: () =>
          import(
            '@router/views/accounting/polyvalence/polyvalence-manager.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'new',
        name: 'polyvalence-create',
        component: () =>
          import('@router/views/accounting/polyvalence/polyvalence-create.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // //////////
  // POLYVALENCE VIEW
  // //////////
  {
    path: '/es/:personnelDivision/polyvalenceView',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'polyvalence-view-date-selection',
        component: () =>
          import(
            '@router/views/accounting/polyvalence/polyvalence-date-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: 'all/:startDate/:endDate',
        name: 'polyvalence-excerpt',
        component: () =>
          import(
            '@router/views/accounting/polyvalence/polyvalence-excerpt.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
      {
        path: ':teamUuid?/:startDate/:endDate',
        name: 'polyvalence-detail',
        component: () =>
          import('@router/views/accounting/polyvalence/polyvalence-detail.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
          ],
        },
      },
    ],
  },
  // ////////////////////////
  // TEAM PERMISSION MANAGEMENT
  // ////////////////////////
  {
    path: '/es/:personnelDivision/teamPermission',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'team-permission-list',
        component: () =>
          import('@views/admin/team-permission/team-permission-list.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
          ],
        },
      },
      {
        path: ':collaboratorUuid',
        name: 'team-permission-management',
        component: () =>
          import('@views/admin/team-permission/team-permission-management.vue'),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
          ],
        },
      },
    ],
  },
  // //////////////////
  // LEGAL ERRORS SITE
  // /////////////////
  {
    path: '/es/:personnelDivision/legalErrors',
    name: 'legal-errors-list-site',
    component: () =>
      import('@views/planning/legal-errors/legal-errors-list-site.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.admin,
        ...siteClearance.director,
        ...siteClearance.organizer,
        ...siteClearance.responsible,
      ],
    },
  },
  // ///////////////////
  // VACATIONS SUMMARY
  /// ///////////////////
  {
    path: '/es/:personnelDivision/vacationsSummary',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'vacations-summary',
        component: () =>
          import(
            '@/src/router/views/planning/vacations-summary/vacations-summary.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.patron,
          ],
        },
      },
      {
        path: ':collaboratorUuid/:year',
        name: 'vacations-collaborator-details',
        component: () =>
          import(
            '@/src/router/views/planning/vacations-collaborator-details/vacations-collaborator-details.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.patron,
          ],
        },
      },
    ],
  },
  /// ////////////////////////////
  // COLLABORATOR VACATIONS PACT
  /// ////////////////////////////
  {
    path: '/es/:personnelDivision/my-vacations-pact-selectYear',
    name: 'collaborator-vacations-pact-select-year',
    component: () =>
      import(
        '@/src/router/views/planning/collaborator-vacations-pact/collaborator-vacations-pact-selectYear.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.organizer,
        ...siteClearance.director,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  {
    path: '/es/:personnelDivision/my-vacations-pact/:selectedYear?',
    name: 'collaborator-vacations-pact',
    component: () =>
      import(
        '@/src/router/views/planning/collaborator-vacations-pact/collaborator-vacations-pact.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.responsible,
        ...siteClearance.collaborator,
        ...siteClearance.organizer,
        ...siteClearance.director,
        ...siteClearance.responsibleCollaborator,
      ],
    },
  },
  /// ////////////////////////////
  // MANAGER: MY TEAM VACATIONS PACT
  /// ////////////////////////////
  {
    path: '/es/:personnelDivision/my-team-vacations-pact',
    // name: 'my-team-vacations-pact',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'my-team-vacations-pact',
        component: () =>
          import(
            '@/src/router/views/planning/responsible-my-team-vacations-pact/responsible-my-team-vacations-pact-selectYear.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.patron,
          ],
        },
      },
      {
        path: ':collaboratorUuid/:year',
        name: 'collaborator-vacations-pact-details',
        component: () =>
          import(
            '@/src/components/planning/responsible-my-team-vacations-pact/collaborator-vacations-pact-details.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [
            ...siteClearance.admin,
            ...siteClearance.director,
            ...siteClearance.organizer,
            ...siteClearance.responsible,
            ...siteClearance.patron,
          ],
        },
      },
    ],
  },
  /// ////////////////////////////
  // ADMIN: VACATION REPORT
  /// ////////////////////////////
  {
    path: '/vacation-report-list',
    // name: 'my-team-vacations-pact',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'vacation-report-list',
        component: () =>
          import(
            '@/src/router/views/planning/vacation-report/vacation-report.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  {
    path: '/reports/legal-errors',
    // name: 'my-team-vacations-pact',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'legal-errors',
        component: () =>
          import(
            '@/src/router/views/admin/reports/legal-errors/legal-errors.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
  },
  /// ////////////////////////////
  // PLANNING ANALYSIS: DIRECTOR, ORGANIZER AND ADMINS
  /// ////////////////////////////
  {
    path: '/es/:personnelDivision/planning-analysis',
    name: 'planning-analysis',
    component: () =>
      import(
        '@/src/router/views/planning/planning-analysis/planning-analysis.vue'
      ),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.organizer,
        ...siteClearance.director,
        ...siteClearance.admin,
        ...siteClearance.directorCoach,
        ...siteClearance.responsible,
        ...siteClearance.multicenterLeader,
      ],
    },
  },

  /// ////////////////////////////
  // MY GAME HOURS: DIRECTOR, ORGANIZER ADMINS AND RESPONSIBLES
  /// ////////////////////////////
  {
    path: '/es/:personnelDivision/my-game-hours',
    name: 'my-game-hours',
    component: () =>
      import('@/src/router/views/planning/my-game-hours/my-game-hours.vue'),
    meta: {
      authRequired: true,
      authorize: [
        ...siteClearance.organizer,
        ...siteClearance.director,
        ...siteClearance.admin,
        ...siteClearance.responsible,
      ],
    },
  },

  // ////////////////////////
  // PLANNING DOWNLOAD
  // ////////////////////////
  {
    path: '/planningDownload',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'planning-download-selection',
        component: () =>
          import(
            '@views/planning/planning-download/planning-download-selection/planning-download-selection.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':personnelDivision/:teamUuid/:startDateInterval',
        name: 'planning-download-team',
        component: () =>
          import(
            '@views/planning/planning-download/planning-download/planning-download.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
      {
        path: ':personnelDivision/:teamUuid/:collaboratorUuid/:startDateInterval/:endDateInterval',
        name: 'planning-download-collaborator',
        component: () =>
          import(
            '@views/planning/planning-download/planning-download/planning-download.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin],
        },
      },
    ],
    // ///////////////////////////////////////
    // SITE TIME SLOTS INTERVALS MANAGEMENT
    // ///////////////////////////////////////
  },
  {
    path: '/es/:personnelDivision/timeSlotsIntervalsManagement',
    component: () => import('@views/empty-router-view.vue'),
    children: [
      {
        path: '',
        name: 'site-time-slots-intervals-list',
        component: () =>
          import(
            '@views/planning/time-slots-intervals-management/time-slots-intervals-management-list.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.director],
        },
      },
      {
        path: 'create',
        name: 'site-time-slots-interval-create',
        component: () =>
          import(
            '@views/planning/time-slots-intervals-management/time-slots-interval-create.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.director],
        },
      },
      {
        path: ':siteTimeSlotUuid',
        name: 'site-time-slots-interval-update',
        component: () =>
          import(
            '@views/planning/time-slots-intervals-management/time-slots-interval-update.vue'
          ),
        meta: {
          authRequired: true,
          authorize: [...siteClearance.admin, ...siteClearance.director],
        },
      },
    ],
  },
]
