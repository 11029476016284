import { createApp } from 'vue'
import router from '@router'
import store from '@state/store'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import './design/element-variables.scss'
import ElementPlus, { dayjs } from 'element-plus'
import es from 'element-plus/es/locale/lang/es.mjs'
import { setupCalendar } from 'v-calendar'
import App from './app.vue'
import i18n from './locale/index'
import 'dayjs/locale/es'
import { clickOutside } from './utils/directives'

import 'typeface-roboto'
import 'typeface-roboto-condensed'

import '@vtmn/icons/dist/vitamix/font/vitamix.css'

import '@vtmn/css/dist/index.css'
import '@vtmn/vue/dist/style.css'

dayjs.locale('es') // Necessary for showing datePickers starting on monday on Element+

const app = createApp(App)

app.use(ElementPlus, { locale: es })
app.use(store)
app.use(router)
app.use(i18n)
app.directive('click-outside', clickOutside)
app.use(setupCalendar, {})

// Global Filters
app.config.globalProperties.$filters = {
  capitalize(value) {
    if (!value) return ''
    value = value.toString()
    const arr = value.split(' ')
    const capitalizedArray = []
    arr.forEach((word) => {
      const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalizedArray.push(capitalized)
    })
    return capitalizedArray.join(' ')
  },
}

app.mount('#app')
