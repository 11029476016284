import deburr from 'lodash/deburr'
export default function sortOptionsByKey(options, key) {
  options.sort((a, b) => {
    const keyA = deburr(a[key].toUpperCase())
    const keyB = deburr(b[key].toUpperCase())
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })
}
