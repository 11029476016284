import { ref } from 'vue'

const useDropDown = (emit) => {
  const dropdown = ref(false)

  // Methods
  const toggleDropDown = () => (dropdown.value = !dropdown.value)

  const clickOutside = () => {
    dropdown.value = false
  }

  const dropDownEvent = (option) => {
    dropdown.value = false
    if (option.fn) return option.fn()
    if (option.path) {
      emit('on:close')
    }

    emit('on:drop-down', option)
  }

  return {
    // Data
    dropdown,

    // Directive
    clickOutside,

    // Methods
    toggleDropDown,
    dropDownEvent,
  }
}

export default useDropDown
