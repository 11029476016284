import {
  contractProcessTypes,
  processStatusesObj,
} from '@utils/constantHelpers'
import { availableTransitions } from './constantHelpers'

export const siteClearance = {
  admin: ['ADMINISTRATOR', 'HR', 'HR_LEADER'],
  directorCoach: ['DIRECTOR_COACH'],
  director: ['DIRECTOR', 'DEPARTMENT_LEADER'],
  responsible: ['RESPONSIBLE', 'TEAM_LEADER'],
  collaborator: ['COLLABORATOR'],
  lawyer: ['LAWYER'],
  organizer: ['ORGANIZER'],
  patron: ['PATRON'],
  responsibleCollaborator: ['RESPONSIBLE_COLLABORATOR'],
  multicenterLeader: ['MULTI_CENTER_LEADER'],
}

export function showCancelLeaveProcessButton(actorRoles, processStatus) {
  return (
    availableTransitions.LEAVE_TRANSITIONS.CANCELLABLE_STATUSES.includes(
      processStatus,
    ) && isAuthorized(actorRoles, siteClearance.admin)
  )
}

export function showCancelLeaveOfAbsenceExtensionButton(
  actorRoles,
  processStatus,
) {
  return (
    availableTransitions.LEAVE_TRANSITIONS.CANCELLABLE_STATUSES.includes(
      processStatus,
    ) && isAuthorized(actorRoles, siteClearance.admin)
  )
}
export function isAuthorized(rolesArr, clearanceArr) {
  return rolesArr.some((element) => {
    return clearanceArr.some((e) => e === element.role.code)
  })
}

/** ******* Contract process actions permissions ******/

export function isAuthorizedToSignOnPaper(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToSignOnPaper = false

  switch (processType) {
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COUNTRY:
      isAuthorizedToSignOnPaper =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
  }
  return isAuthorizedToSignOnPaper
}

export function isAuthorizedToSendEmail(processType, currentStatus) {
  let isAuthorizedToSendEmail = false

  switch (processType) {
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY:
      isAuthorizedToSendEmail = [
        processStatusesObj.QUEUED_SEND_EMAIL_PENDING,
      ].includes(currentStatus)
      break
  }
  return isAuthorizedToSendEmail
}

export function isAuthorizedToCancelProcess(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToCancelProcess = false
  switch (processType) {
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COUNTRY:
      isAuthorizedToCancelProcess =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
          processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
          processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY:
      isAuthorizedToCancelProcess =
        (isAuthorized(actorRoles, siteClearance.admin) &&
          [
            processStatusesObj.WAITING_FOR_HR_DATA,
            processStatusesObj.WAITING_TO_SEND_CONTRACT,
            processStatusesObj.WAITING_TO_SIGN_CONTRACT,
          ].includes(currentStatus)) ||
        [
          processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
          processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
          processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
          processStatusesObj.QUEUED_SEND_EMAIL_PENDING,
          processStatusesObj.WAITING_FOR_COLLABORATOR_DATA,
        ].includes(currentStatus)
      break
  }
  return isAuthorizedToCancelProcess
}

export function isAuthorizedToEditProcess(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToEditProcess = false
  switch (processType) {
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COUNTRY:
      isAuthorizedToEditProcess =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
          processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
          processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY:
      isAuthorizedToEditProcess =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_HR_DATA,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
        ].includes(currentStatus)
      break
  }

  return isAuthorizedToEditProcess
}

export function isAuthorizedToViewPdfContract(
  processType,
  currentStatus,
  actorRoles,
) {
  let isAuthorizedToViewPdfContract = false

  switch (processType) {
    case contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COUNTRY:
      isAuthorizedToViewPdfContract = isAuthorized(
        actorRoles,
        siteClearance.admin,
      )
      break
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COUNTRY:
      isAuthorizedToViewPdfContract =
        isAuthorized(actorRoles, siteClearance.admin) &&
        [
          processStatusesObj.WAITING_FOR_HR_DATA,
          processStatusesObj.WAITING_TO_SEND_CONTRACT,
          processStatusesObj.WAITING_TO_SIGN_CONTRACT,
          processStatusesObj.SIGNED,
          processStatusesObj.SIGNED_ON_PAPER,
          processStatusesObj.COMPLETED_SUCCESS,
        ].includes(currentStatus)
      break
  }
  return isAuthorizedToViewPdfContract
}
export function isAuthorizedToRectifyProcess(processType, currentStatus) {
  let isAuthorizedToRectifyProcess = false
  switch (processType) {
    case contractProcessTypes.HIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_CONTRACT_PROCESS_COUNTRY:
    case contractProcessTypes.REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY:
      isAuthorizedToRectifyProcess = [
        processStatusesObj.WAITING_FOR_DIRECTOR_VALIDATION,
        processStatusesObj.WAITING_FOR_LEGAL_VALIDATION,
        processStatusesObj.WAITING_FOR_DIRECTOR_AND_LEGAL_VALIDATION,
        processStatusesObj.QUEUED_SEND_EMAIL_PENDING,
        processStatusesObj.WAITING_FOR_COLLABORATOR_DATA,
      ].includes(currentStatus)
      break
  }
  return isAuthorizedToRectifyProcess
}

export function isAuthorizedToSendContract(processType, processStatus) {
  if (
    processType === contractProcessTypes.EXTENSION_CONTRACT_PROCESS_COUNTRY ||
    processType === contractProcessTypes.CONVERSION_CONTRACT_PROCESS_COUNTRY
  ) {
    return processStatusesObj.WAITING_TO_SEND_CONTRACT.includes(processStatus)
  }
  return false
}
/** ********************************************* */

export const centralServicesPersonnelDivision = '0002'

//
// Special roles such as HR are excluded from this list, since they should be assigned additionally to one of next listed
export const rolesByHierarchyLevel = {
  DIRECTOR: [
    'DIRECTOR',
    'DIRECTOR_COACH',
    'DEPARTMENT_LEADER',
    'ORGANIZER',
    'MULTI_CENTER_LEADER',
    'COUNTRY_LEADER',
  ],
  RESPONSIBLE: ['RESPONSIBLE', 'TEAM_LEADER'],
  COLLABORATOR: ['COLLABORATOR', 'RESPONSIBLE_COLLABORATOR'],
}
