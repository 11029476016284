<template>
  <footer class="vtmn-bg-background-brand-primary">
    <div
      class="vtmn-max-w-7xl vtmn-mx-auto vtmn-py-12 vtmn-px-4 mobile:vtmn-px-6 gt-tablet:vtmn-flex gt-tablet:vtmn-items-center gt-tablet:vtmn-justify-between gt-small-desktop:vtmn-px-8 gt-small-desktop:vtmn-py-2"
    >
      <div
        class="vtmn-mt-8 vtmn-flex vtmn-justify-between gt-tablet:vtmn-mt-0 gt-tablet:vtmn-order-2"
      >
        <div class="vtmn-max-w-md">
          <p
            class="vtmn-text-xs vtmn-text-right vtmn-text-white mobile:vtmn-text-center mobile:vtmn-pt-2"
            data-jest="googleTagManager-text"
          >
            {{ $tc('message.footerMessage') }}
            <a
              class="vtmn-text-content-action-reversed vtmn-cursor-pointer vtmn-font-bold"
              @click="openPreferences"
            >
              {{ $tc('message.consentPreferences') }}
            </a>
          </p>
        </div>
      </div>
      <div
        class="vtmn-py-4 gt-tablet:vtmn-mt-0 gt-tablet:vtmn-py-0 gt-tablet:vtmn-order-1"
      >
        <p
          class="vtmn-text-center vtmn-text-base vtmn-text-white"
          data-jest="copyright-text"
        >
          &copy; {{ currentYear }} Decathlon España
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue'
const currentYear = ref(new Date().getFullYear())
const openPreferences = () => {
  // eslint-disable-next-line no-undef
  Didomi.preferences.show()
}
</script>
