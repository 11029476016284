import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

export const state = {
  rebillsIssued: [],
  rebillsReceived: [],
  rebillingProcesses: [],
  polyvalenceProcesses: [],
  polyvalenceExcerpt: [],
  polyvalenceDetail: [],
  cats: [],
  sapNumbers: [],
}

export const getters = {}

export const mutations = {
  UPDATE_REBILLS_ISSUED(state, rebillsIssued) {
    state.rebillsIssued = rebillsIssued
  },
  UPDATE_REBILLS_RECEIVED(state, rebillsReceived) {
    state.rebillsReceived = rebillsReceived
  },
  UPDATE_REBILLING_MANAGEMENT(state, rebillingProcesses) {
    state.rebillingProcesses = rebillingProcesses
  },
  UPDATE_PAYROLL_PAYMENT_CATS(state, catsData) {
    state.cats = catsData
  },
  UPDATE_PAYROLL_PAYMENT_SAP_NUMBERS(state, sapNumbers) {
    state.sapNumbers = sapNumbers
  },
  UPDATE_POLYVALENCE_PROCESSES(state, polyvalenceProcesses) {
    state.polyvalenceProcesses = polyvalenceProcesses
  },
  UPDATE_POLYVALENCE_EXCERPT(state, polyvalenceExcerpt) {
    state.polyvalenceExcerpt = polyvalenceExcerpt
  },
  UPDATE_POLYVALENCE_DETAIL(state, polyvalenceDetail) {
    state.polyvalenceDetail = polyvalenceDetail
  },
}

export const actions = {
  // GET REBILLS ISSUED
  // GET
  async fetchRebillsIssuedAction(store, filters) {
    store.commit('UPDATE_REBILLS_ISSUED', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/planning/api/v2/rebilling/getRebillingIssued${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_REBILLS_ISSUED', res.data.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // GET REBILLS RECEIVED
  // GET
  async fetchRebillsReceivedAction(store, filters) {
    store.commit('UPDATE_REBILLS_RECEIVED', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/planning/api/v2/rebilling/getRebillingReceived${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_REBILLS_RECEIVED', res.data.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // GET REBILLS RECEIVED
  // GET
  async fetchRebillingManagementAction(store, filters) {
    store.commit('UPDATE_REBILLING_MANAGEMENT', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/planning/api/v2/rebilling/getManagementRebilling${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_REBILLING_MANAGEMENT', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // GET POLYVALENCE PROCESSES
  // GET
  async fetchPolyvalenceProcessesAction(store, filters) {
    store.commit('UPDATE_POLYVALENCE_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/planning/api/v2/polyvalence/getPolyvalence${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_POLYVALENCE_PROCESSES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // GET POLYVALENCE EXCERPT
  // GET
  async fetchPolyvalenceExcerptAction(store, filters) {
    store.commit('UPDATE_POLYVALENCE_EXCERPT', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/planning/api/v2/polyvalence/getPolyvalenceByHR${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_POLYVALENCE_EXCERPT', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // GET POLYVALENCE DETAIL
  // GET
  async fetchPolyvalenceDetailAction(store, filters) {
    store.commit('UPDATE_POLYVALENCE_DETAIL', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/planning/api/v2/polyvalence/getPolyvalenceByHR${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_POLYVALENCE_DETAIL', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // CREATE REBILLING
  // POST
  async createRebillingAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/planning/api/v2/rebilling/create.rebilling`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/planning/api/v2/rebilling/create.rebilling: ${e}`,
      )
    }
  },
  // DELETE REBILLING
  // POST
  async deleteRebillingAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/planning/api/v2/rebilling/delete.rebilling`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/planning/api/v2/rebilling/delete.rebilling: ${e}`,
      )
    }
  },
  // CREATE POLYVALENCE
  // POST
  async createPolyvalenceAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/planning/api/v2/polyvalence/create`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/planning/api/v2/polyvalence/create: ${e}`,
      )
    }
  },
  // DELETE POLYVALENCE
  // POST
  async deletePolyvalenceAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/planning/api/v2/polyvalence/delete`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /ees/planning/api/v2/polyvalence/delete: ${e}`,
      )
    }
    // GET PAYROLL PAYMENT
    // GET
  },
  async fetchPayrollPaymentAction(store, filters) {
    store.commit('UPDATE_PAYROLL_PAYMENT_CATS', [])
    store.commit('UPDATE_PAYROLL_PAYMENT_SAP_NUMBERS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/planning/api/v2/payrollPayment${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_PAYROLL_PAYMENT_CATS', res.data.catsData)
      store.commit(
        'UPDATE_PAYROLL_PAYMENT_SAP_NUMBERS',
        res.data.involvedSapNumbers,
      )
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
}
