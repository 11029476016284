<template>
  <ul
    class="vtmn-flex vtmn-w-full gt-medium-desktop:vtmn-w-auto vtmn-flex-col vtmn-space-y-2 gt-medium-desktop:vtmn-space-y-0 gt-medium-desktop:vtmn-flex-row gt-medium-desktop:vtmn-space-x-2"
  >
    <!-- Dropdown options -->
    <li v-click-outside="() => clickOutside()" class="vtmn-relative">
      <button
        class="vtmn-flex vtmn-items-center vtmn-px-4 vtmn-w-full vtmn-py-2 vtmn-typo_text-1 vtmn-text-background-primary vtmn-rounded-100 vtmn-cursor-pointer"
        @click="toggleDropDown"
        ><slot /><p class="eplanning-dropdown_toggle vtmn-mt-1">{{
          dropdownLabel
        }}</p></button
      >
      <!-- Dropdown menu -->
      <div
        class="vtmn-shadow-100 vtmn-z-10 vtmn-mb-2 vtmn-bg-background-brand-primary-reversed vtmn-w-full vtmn-right-0 gt-medium-desktop:vtmn-min-w-max vtmn-rounded-100 vtmn-py-2 gt-medium-desktop:vtmn-absolute vtmn-mt-1 vtmn-z-10"
        :class="{
          'vtmn-hidden': !dropdown,
          'vtmn-flex vtmn-flex-col': dropdown,
        }"
      >
        <ul class="vtmn-space-y-2">
          <li
            v-for="(option, index) in dropDownOptions"
            :key="index"
            @click="() => triggerEmitEventAndDropDownEvent(option)"
          >
            <p
              class="vtmn-cursor-pointer vtmn-typo_text-1 vtmn-flex vtmn-p-2 vtmn-px-4 hover:vtmn-bg-background-brand-secondary"
              >{{ $filters.capitalize(option?.label) || '' }}</p
            >
            <div class="vtmn-block gt-medium-desktop:vtmn-hidden">
              <div class="vtmn-divider" role="separator"></div>
            </div>
          </li>
        </ul>
      </div>
      <!-- Dropdown menu -->
    </li>
    <!-- Dropdown options -->
  </ul>
</template>
<script>
import useDropDown from '@/src/composables/useDropDown'

export default {
  props: {
    dropdownLabel: {
      type: String,
      default: '',
    },
    dropDownOptions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['switchLocale'],
  setup(props, { emit }) {
    const emitSwitchLocale = (lang) => {
      emit('switchLocale', lang)
    }
    const triggerEmitEventAndDropDownEvent = (option) => {
      emitSwitchLocale(option)
      dropDownEvent(option)
    }
    const { dropdown, toggleDropDown, clickOutside, dropDownEvent } =
      useDropDown(emit)

    return {
      // Data
      dropdown,

      // Methods
      toggleDropDown,
      dropDownEvent,
      triggerEmitEventAndDropDownEvent,
      emitSwitchLocale,

      // Directive
      clickOutside,
    }
  },
}
</script>
