<script>
import Layout from '@layouts/anonymous-layout.vue'

export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }],
  },
  components: { Layout },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <Layout>
    <div :class="$style.notFound">
      <h1> 404 Not Found </h1>
      <img src="../../assets/images/10455.png" alt="404" />
      <div>
        <u
          ><a @click="$router.go(-1)">{{ $t('message.back') }}</a></u
        >
        |
        <u>
          <a @click="$router.push('/')">{{ $t('message.home') }}</a>
        </u>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 55vh;
  text-align: center;
  a {
    font-weight: 400;
    color: $color-decathlon-blue !important;
    cursor: pointer;
  }
  h1 {
    margin: 0;
  }
}
</style>
