import axios from '@utils/axiosInstance'
import queryStringFilterBuilder from '@utils/queryStringFilterBuilder'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    collaboratorNumberOfVacationsDays: null,
    teamVacationAgreement: [],
    collaboratorCurrentYearVacationAgreement: null,
    collaboratorVacationAgreement: null,
    vacationReportList: null,
    collaboratorVacationAndAbsenteeism: null,
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  RESET_STATE() {
    this.state = getDefaultState()
  },
  UPDATE_COLLABORATOR_NUMBER_OF_VACATIONS_DAYS(
    state,
    collaboratorNumberOfVacationsDays,
  ) {
    state.collaboratorNumberOfVacationsDays = collaboratorNumberOfVacationsDays
  },
  UPDATE_TEAM_VACATION_AGREEMENT(state, teamVacationAgreement) {
    state.teamVacationAgreement = teamVacationAgreement
  },
  UPDATE_COLLABORATOR_CURRENT_YEAR_VACATION_AGREEMENT(
    state,
    collaboratorCurrentYearVacationAgreement,
  ) {
    state.collaboratorCurrentYearVacationAgreement =
      collaboratorCurrentYearVacationAgreement
  },
  UPDATE_COLLABORATOR_VACATION_AGREEMENT(state, collaboratorVacationAgreement) {
    state.collaboratorVacationAgreement = collaboratorVacationAgreement
  },
  UPDATE_VACATION_REPORT_LIST(state, vacationReportList) {
    state.vacationReportList = vacationReportList
  },
  UPDATE_COLLABORATOR_VACATION_AND_ABSENTEEISM(
    state,
    collaboratorVacationAndAbsenteeism,
  ) {
    state.collaboratorVacationAndAbsenteeism =
      collaboratorVacationAndAbsenteeism
  },
}

export const actions = {
  /** *********** VACATIONS AGREEMENT ACTIONS ***************************/
  // FETCH COLLABORATOR VACATIONS DAYS FOR CURRENT YEAR (COLLABORATOR ACTION)
  // GET
  async fetchCollaboratorNumberOfVacationsDaysAction(store, filters) {
    const queryString = queryStringFilterBuilder(filters)
    const url = `${BASE}/es/planning/api/v2/vacation/collaboratorDetails${queryString}`
    try {
      store.commit('UPDATE_COLLABORATOR_NUMBER_OF_VACATIONS_DAYS', null)
      const resp = await axios.get(url)
      store.commit('UPDATE_COLLABORATOR_NUMBER_OF_VACATIONS_DAYS', resp.data)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // START COLLABORATOR VACATION AGREEMENT PROCESS (COLLABORATOR ACTION)
  async startVacationAgreementProcessAction(store, payload) {
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/start`
    try {
      const resp = await axios.post(url, payload)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // EDIT COLLABORATOR VACATION AGREEMENT PROCESS (COLLABORATOR ACTION)
  // POST
  async editVacationAgreementProcessAction(store, payload) {
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/edit`
    try {
      const resp = await axios.post(url, payload)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // SEND COLLABORATOR VACATION AGREEMENT PROCESS (COLLABORATOR ACTION)
  // POST
  async sendVacationAgreementProcessAction(store, payload) {
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/send`
    try {
      const resp = await axios.post(url, payload)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // GET
  // FETCH LATEST COLLABORATOR VACATION AGREEMENT FOR CURRENT YEAR (COLLABORATOR ACTION)
  async fetchVacationAgreementForYearAction(store, payload) {
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/lastOfYear?filter[year]=${payload.year}`
    try {
      store.commit('UPDATE_COLLABORATOR_CURRENT_YEAR_VACATION_AGREEMENT', null)
      const resp = await axios.get(url)
      store.commit(
        'UPDATE_COLLABORATOR_CURRENT_YEAR_VACATION_AGREEMENT',
        resp.data[0],
      )
      return resp.data[0]
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  async fetchVacationAgreementForSelectedYearAction(store, payload) {
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/${payload.yearSelected}`
    try {
      store.commit('UPDATE_COLLABORATOR_CURRENT_YEAR_VACATION_AGREEMENT', null)
      const resp = await axios.get(url)
      store.commit(
        'UPDATE_COLLABORATOR_CURRENT_YEAR_VACATION_AGREEMENT',
        resp.data[0],
      )
      return resp.data[0]
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // UNLOCK VACATION AGREEMENT PROCESS (MANAGER ACTION)
  // POST
  async initializeVacationAgreementProcessAction(store, payload) {
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/initialize`
    try {
      const resp = await axios.post(url, payload)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // ACCEPT VACATION AGREMENT (MANAGER ACTION)
  // POST
  async acceptVacationAgreementProcessAction(store, payload) {
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/accept`
    try {
      const resp = await axios.post(url, payload)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // FETCH COLLABORATOR VACATION AGREEMENT (MANAGER ACTION)

  async fetchCollaboratorVacationAgreementProcessAction(store, filters) {
    const queryString = queryStringFilterBuilder(filters)
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/collaborator${queryString}`
    try {
      store.commit('UPDATE_COLLABORATOR_VACATION_AGREEMENT', null)
      const resp = await axios.get(url)
      store.commit('UPDATE_COLLABORATOR_VACATION_AGREEMENT', resp.data)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // FETCH TEAM VACATION AGREEMENT (MANAGER ACTION)
  async fetchTeamVacationAgreementProcessAction(store, filters) {
    const queryString = queryStringFilterBuilder(filters)
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/team${queryString}`
    try {
      store.commit('UPDATE_TEAM_VACATION_AGREEMENT', [])
      const resp = await axios.get(url)
      store.commit('UPDATE_TEAM_VACATION_AGREEMENT', resp.data)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // FETCH VACATION REPORT LIST
  async fetchVacationReportListAction(store) {
    const url = `${BASE}/es/planning/api/v2/vacation/report`
    try {
      store.commit('UPDATE_VACATION_REPORT_LIST', [])
      const resp = await axios.get(url)
      store.commit('UPDATE_VACATION_REPORT_LIST', resp.data)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // FETCH COLLABORATOR VACATION AGREEMENT (MANAGER ACTION)

  async fetchCollaboratorVacationAndAbsenteeismAction(store, filters) {
    const queryString = queryStringFilterBuilder(filters)
    const url = `${BASE}/es/planning/api/v2/vacationAgreementProcess/collaborator/vacationAndAbsenteeism${queryString}`
    try {
      store.commit('UPDATE_COLLABORATOR_VACATION_AND_ABSENTEEISM', null)
      const resp = await axios.get(url)
      store.commit('UPDATE_COLLABORATOR_VACATION_AND_ABSENTEEISM', resp.data)
      return resp.data
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
}
