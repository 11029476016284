import { User } from '@state/infrastructure/User'
import axios from '@utils/axiosInstance'
import jwtDecode from 'jwt-decode'
import { siteClearance } from '@utils/clearanceHelpers'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

export const state = {
  currentUser: getSavedState('auth.currentUser'),
  authToken: getSavedState('auth.token'),
  idToken: getSavedState('auth.idToken'),
  refreshToken: getSavedState('auth.refreshToken'),
  actorRoles: getSavedState('auth.actorRoles'),
  collaboratorUuid: getSavedState('auth.collaboratorUuid'),
  currentContractType: getSavedState('currentContractType'),
  fedIdUser: null,
  eplanning2PositionCode: getSavedState('eplanning2PositionCode'),
  eplanning2PositionName: getSavedState('eplanning2PositionName'),
  identityAccessPositionCode: getSavedState('identityAccessPositionCode'),
  identityAccessPositionName: getSavedState('identityAccessPositionName'),
  eplanning2Username: getSavedState('eplanning2Username'),
  identityAccessUsername: getSavedState('identityAccessUsername'),
  correctIdentityAccessPosition: null,
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
}

export const mutations = {
  updateUser(state, fedIdUser) {
    state.fedIdUser = fedIdUser
  },
  SET_CURRENT_USER(state, newValue) {
    if (newValue.uid) {
      newValue.uid = newValue.uid.toUpperCase()
    }
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },
  SET_AUTH_TOKEN(state, token) {
    state.authToken = token
    saveState('auth.token', token)
  },
  SET_ID_TOKEN(state, idToken) {
    state.idToken = idToken
    saveState('auth.idToken', idToken)
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken
    saveState('auth.refreshToken', refreshToken)
  },
  SET_ACTOR_ROLES(state, actorRoles) {
    state.actorRoles = actorRoles
    saveState('auth.actorRoles', actorRoles)
  },
  SET_COLLABORATOR_UUID(state, collaboratorUuid) {
    state.collaboratorUuid = collaboratorUuid
    saveState('auth.collaboratorUuid', collaboratorUuid)
  },
  SET_CURRENT_CONTRACT_TYPE(state, currentContractType) {
    state.currentContractType = currentContractType
    saveState('currentContractType', currentContractType)
  },
  LOGOUT() {
    clearStorage()
    setTimeout(window.location.reload.bind(location), 5000)
  },
  SET_EPLANNING2_POSITION_CODE(state, eplanning2PositionCode) {
    state.eplanning2PositionCode = eplanning2PositionCode
    saveState('eplanning2PositionCode', eplanning2PositionCode)
  },
  SET_EPLANNING2_POSITION_NAME(state, eplanning2PositionName) {
    state.eplanning2PositionName = eplanning2PositionName
    saveState('eplanning2PositionName', eplanning2PositionName)
  },

  SET_IDENTITY_ACCESS_POSITION_CODE(state, identityAccessPositionCode) {
    state.identityAccessPositionCode = identityAccessPositionCode
    saveState('identityAccessPositionCode', identityAccessPositionCode)
  },
  SET_IDENTITY_ACCESS_POSITION_NAME(state, identityAccessPositionName) {
    state.identityAccessPositionName = identityAccessPositionName
    saveState('identityAccessPositionName', identityAccessPositionName)
  },
  SET_EPLANNING2_USERNAME(state, eplanning2Username) {
    state.eplanning2Username = eplanning2Username
    saveState('eplanning2Username', eplanning2Username)
  },
  SET_IDENTITY_ACCESS_USERNAME(state, identityAccessUsername) {
    state.identityAccessUsername = identityAccessUsername
    saveState('identityAccessUsername', identityAccessUsername)
  },
  SET_CORRECT_IDENTITY_ACCESS_POSITION(state, correctIdentityAccessPosition) {
    state.correctIdentityAccessPosition = correctIdentityAccessPosition
  },
}

export const actions = {
  async getFedIdUrl() {
    try {
      return await axios.get(
        `${BASE}/api/v2/actor/idpDecathlonOxylane/getLoginGateway`,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred at /api/v2/actor/idpDecathlonOxylane/getLoginGateway`,
      )
    }
  },

  async sendToken({ commit }, payload) {
    try {
      const response = await axios.post(
        `${BASE}/api/v2/actor/idpDecathlonOxylane/getJwtTokenFromCode`,
        payload,
      )

      const fedIdUser = jwtDecode(response.data.idToken)
      const AUTH_TOKEN = response.data.accessToken
      const ID_TOKEN = response.data.idToken
      const REFRESH_TOKEN = response.data.refreshToken
      const IDENTITY_ACCESS_POSITION_CODE = fedIdUser.jobname ?? null
      const IDENTITY_ACCESS_USERNAME = fedIdUser.uid ?? null
      const IDENTITY_ACCESS_POSITION_NAME = fedIdUser.title ?? null
      commit('updateUser', fedIdUser)
      commit('SET_AUTH_TOKEN', AUTH_TOKEN)
      commit('SET_ID_TOKEN', ID_TOKEN)
      commit('SET_REFRESH_TOKEN', REFRESH_TOKEN)

      commit('SET_IDENTITY_ACCESS_POSITION_CODE', IDENTITY_ACCESS_POSITION_CODE)
      commit('SET_IDENTITY_ACCESS_POSITION_NAME', IDENTITY_ACCESS_POSITION_NAME)
      commit('SET_IDENTITY_ACCESS_USERNAME', IDENTITY_ACCESS_USERNAME)

      const token = state.authToken
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
      }

      return fedIdUser
    } catch (e) {
      throw Error(
        `Auth error ocurred in ${BASE}/api/v2/actor/idpDecathlonOxylane/getJwtTokenFromCode ${e}`,
      )
    }
  },

  async sendRefreshToken({ commit }, payload) {
    try {
      const response = await axios.post(
        `${BASE}/api/v2/actor/idpDecathlonOxylane/getJwtFromRefreshToken`,
        payload,
      )

      const AUTH_TOKEN = response.data.accessToken
      const REFRESH_TOKEN = response.data.refreshToken
      commit('SET_AUTH_TOKEN', AUTH_TOKEN)
      commit('SET_REFRESH_TOKEN', REFRESH_TOKEN)

      const token = JSON.parse(localStorage.getItem('auth.token'))
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
      }

      return response
    } catch (e) {
      throw Error(
        `Auth error ocurred in ${BASE}/api/v1/common/idpDecathlonOxylane/getJwtFromRefreshToken ${e}`,
      )
    }
  },

  logInFedId({ commit, dispatch, getters }, fedIdUser) {
    if (getters.loggedIn) return dispatch('validate')
    commit('SET_CURRENT_USER', fedIdUser)
  },

  async fetchSelfProfile({ commit }) {
    try {
      const response = await axios.get(`${BASE}/api/v2/actor/profile`)
      const ACTOR_ROLES = response.data.actorRoles
      const COLLABORATOR_UUID = response.data.collaborator.uuid
      const CURRENT_CONTRACT_TYPE = response.data.collaborator.activeContract
        ? response.data.collaborator.activeContract.contractType.type
        : response.data.collaborator.latestContract
        ? response.data.collaborator.latestContract.contractType.type
        : null
      const EPLANNING2_POSITION_CODE =
        response.data.collaborator.latestSuccessfulLaborCondition?.position
          ?.identityAccessCode ?? null

      const EPLANNING2_POSITION_NAME =
        response.data.collaborator.latestSuccessfulLaborCondition?.position
          ?.name ?? null
      const EPLANNING2_USERNAME = response.data.username
      commit('SET_ACTOR_ROLES', ACTOR_ROLES)
      commit('SET_COLLABORATOR_UUID', COLLABORATOR_UUID)
      commit('SET_CURRENT_CONTRACT_TYPE', CURRENT_CONTRACT_TYPE)
      commit('SET_EPLANNING2_POSITION_CODE', EPLANNING2_POSITION_CODE)
      commit('SET_EPLANNING2_USERNAME', EPLANNING2_USERNAME)
      commit('SET_EPLANNING2_POSITION_NAME', EPLANNING2_POSITION_NAME)
    } catch (error) {
      throw new Error(`API Error occurred at /api/v2/actor/profile`)
    }
  },

  async fetchIdentityAccessPosition({ commit }, payload) {
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/identityAccessPosition/${payload}`,
      )
      const CORRECT_IDENTITY_ACCESS_POSITION = response.data

      commit(
        'SET_CORRECT_IDENTITY_ACCESS_POSITION',
        CORRECT_IDENTITY_ACCESS_POSITION,
      )
    } catch (error) {
      throw new Error(`API Error occurred at /api/v2/identityAccessPosition`)
    }
  },

  async sendEmailCorrectIdentityPosition({ commit }, payload) {
    try {
      const response = await axios.post(
        `${BASE}/es/api/v2/notifyUnMatchIdentityAccessPosition`,
        payload,
      )
      return response
    } catch (e) {
      throw Error(
        `Auth error ocurred in ${BASE}/es/api/v2/notifyUnMatchIdentityAccessPosition`,
      )
    }
  },

  // Logs out the current user.
  async logOut({ commit }) {
    try {
      const payload = { idToken: state.idToken }
      await axios.post(
        `${BASE}/api/v2/actor/idpDecathlonOxylane/revokeSession`,
        payload,
      )
      return await commit('LOGOUT')
    } catch (e) {
      throw Error(
        `Auth error ocurred in ${BASE}/api/v2/actor/idpDecathlonOxylane/revokeSession`,
      )
    }
  },

  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)
    const user = new User(
      state.currentUser.aud,
      state.currentUser.auth_time,
      state.currentUser.c,
      state.currentUser.email,
      state.currentUser.exp,
      state.currentUser.familyName,
      state.currentUser.federation_idp,
      state.currentUser.givenName,
      state.currentUser.iat,
      state.currentUser.iss,
      state.currentUser.jti,
      state.currentUser.mail,
      state.currentUser.name,
      state.currentUser.objectclass,
      state.currentUser['pi.sri'],
      state.currentUser.preferredLanguage,
      state.currentUser.sn,
      state.currentUser.sub,
      state.currentUser.uid,
      state.currentUser.uuid,
    )
    if (
      state.currentUser.uuid !== user.uuid ||
      state.currentUser.uid !== user.uid
    ) {
      commit('LOGOUT')
    } else {
      return Promise.resolve(user)
    }
  },
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function clearStorage(key) {
  window.localStorage.clear()
}

// Look in actorRoles if current user can work on the requested site. Always true for admins.
export function isAllowedOnSite(siteCode) {
  return state.actorRoles.some((element) => {
    if (siteClearance.admin.some((e) => e === element.role.code)) return true
    return state.actorRoles.some((element) => {
      let result = false
      if (element.site)
        // If there is a role, but no site specified, no site will be allowed for this user.
        result = siteCode === element.site.personnelDivision
      return result
    })
  })
}
