import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    leaveProcess: null,
    leaveReasons: [],
    pendingSapLeaveProcesses: [],
    sapLeaveRecords: [],
    sapLeaveCats: [],
    openLeaveProcesses: null,
    leaveOfAbsenceExtensionProcesses: null,
    notRehireableReasons: {},
    sapLeaveOfAbsenceExtensionProcesses: [],
    sapLeaveOfAbsenceExtensionRecords: [],
    leaveOfAbsenceDocument: null,
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_LEAVE_PROCESS(state, leaveProcess) {
    state.leaveProcess = leaveProcess
  },
  UPDATE_LEAVE_REASONS(state, leaveReasons) {
    state.leaveReasons = leaveReasons
  },
  UPDATE_PENDING_SAP_LEAVE_PROCESSES(state, pendingSapLeaveProcesses) {
    state.pendingSapLeaveProcesses = pendingSapLeaveProcesses
  },
  UPDATE_SAP_LEAVE_OF_ABSENCE_EXTENSION_PROCESSES(
    state,
    sapLeaveOfAbsenceExtensionProcesses,
  ) {
    state.sapLeaveOfAbsenceExtensionProcesses =
      sapLeaveOfAbsenceExtensionProcesses
  },
  UPDATE_SAP_LEAVE_RECORDS(state, sapLeaveRecords) {
    state.sapLeaveRecords = sapLeaveRecords
  },
  UPDATE_SAP_LEAVE_CATS(state, cats) {
    state.sapLeaveCats = cats
  },
  UPDATE_SAP_LEAVE_OF_ABSENCE_EXTENSION_RECORDS(
    state,
    sapLeaveOfAbsenceExtensionRecords,
  ) {
    state.sapLeaveOfAbsenceExtensionRecords = sapLeaveOfAbsenceExtensionRecords
  },
  UPDATE_OPEN_LEAVE_PROCESSES(state, openLeaveProcesses) {
    state.openLeaveProcesses = openLeaveProcesses
  },
  UPDATE_NOT_REHIREABLE_REASONS(state, notRehireableReasons) {
    state.notRehireableReasons = notRehireableReasons
  },
  RESET_LEAVE_MANAGER_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_LEAVE_OF_ABSENCE_PROCESSES(state, leaveOfAbsenceProcesses) {
    state.leaveOfAbsenceProcesses = leaveOfAbsenceProcesses
  },
  UPDATE_SITE_LEAVE_OF_ABSENCE_PROCESSES(state, leaveOfAbsenceProcesses) {
    state.leaveOfAbsenceProcesses = leaveOfAbsenceProcesses
  },
  UPDATE_LEAVE_OF_ABSENCE_DOCUMENT(state, leaveOfAbsenceDocument) {
    state.leaveOfAbsenceDocument = leaveOfAbsenceDocument
  },
  UPDATE_LEAVE_OF_ABSENCE_EXTENSION_PROCESS(
    state,
    leaveOfAbsenceExtensionProcess,
  ) {
    state.leaveOfAbsenceExtensionProcess = leaveOfAbsenceExtensionProcess
  },
}

export const actions = {
  // LEAVE PROCESS
  // GET
  async fetchLeaveProcess(store, processUuid) {
    try {
      store.commit('UPDATE_LEAVE_PROCESS', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/leaveProcesses/${processUuid}`,
      )
      store.commit('UPDATE_LEAVE_PROCESS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/leaveProcesses/${processUuid}`,
      )
    }
  },
  // OPEN LEAVE PROCESSES
  // GET
  async fetchOpenLeaveProcesses(store, filters = {}) {
    store.commit('UPDATE_OPEN_LEAVE_PROCESSES', null)
    const filtersArr = joinFilters(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/leaveProcesses.common/unfinishedStatus${queryString}`,
      )
      store.commit('UPDATE_OPEN_LEAVE_PROCESSES', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in es/api/v2/leaveProcesses.common/unfinishedStatus.',
      )
    }
  },
  async fetchLeaveOfAbsenceProcesses(store, filters = {}) {
    store.commit('UPDATE_LEAVE_OF_ABSENCE_PROCESSES', null)
    const filtersArr = joinFilters(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/leave.leaveOfAbsenceByHRList${queryString}`,
      )
      store.commit('UPDATE_LEAVE_OF_ABSENCE_PROCESSES', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /es/api/v2/contractManager/leave.leaveOfAbsenceByHRList.',
      )
    }
  },
  async fetchSiteLeaveOfAbsenceProcesses(store, filters = {}) {
    store.commit('UPDATE_SITE_LEAVE_OF_ABSENCE_PROCESSES', null)
    const filtersArr = joinFilters(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/leave.leaveOfAbsenceList${queryString}`,
      )
      store.commit('UPDATE_SITE_LEAVE_OF_ABSENCE_PROCESSES', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /es/api/v2/contractManager/leave.leaveOfAbsenceList.',
      )
    }
  },
  async fetchLeaveOfAbsenceDocumentAction(store, collaboratorUuid) {
    try {
      store.commit('UPDATE_LEAVE_OF_ABSENCE_DOCUMENT', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/leave.documentLeaveOfAbsence/${collaboratorUuid}`,
      )
      store.commit('UPDATE_LEAVE_OF_ABSENCE_DOCUMENT', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contractManager/leave.documentLeaveOfAbsence/${collaboratorUuid}`,
      )
    }
  },
  async fetchLeaveOfAbsenceExtensionProcessByUuid(store, processUuid) {
    try {
      store.commit('UPDATE_LEAVE_OF_ABSENCE_EXTENSION_PROCESS', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/leave.leaveOfAbsenceExtension/${processUuid}`,
      )
      store.commit('UPDATE_LEAVE_OF_ABSENCE_EXTENSION_PROCESS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/leaveProcesses/${processUuid}`,
      )
    }
  },
  // LEAVE REASONS
  // GET
  async fetchLeaveReasons(store, collaboratorUuid) {
    store.commit('UPDATE_LEAVE_REASONS', [])
    const queryParams = collaboratorUuid
      ? `?filter[collaboratorUuid]=${collaboratorUuid}`
      : ''
    const url = `${BASE}/es/api/v2/applicableLeaveReasons${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_LEAVE_REASONS', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // SAP PENDING PROCESSES
  // GET
  // GOODBYE ASYNC/AWAIT
  fetchPendingSapLeaveProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_SAP_LEAVE_PROCESSES', [])
    const filtersArr = joinFiltersSAP(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BASE}/es/api/v2/leaveProcesses.common/pendingHRManagement${queryString}`,
        )
        .then((res) => {
          store.commit('UPDATE_PENDING_SAP_LEAVE_PROCESSES', res.data)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSapLeaveOfAbsenceExtensionProcesses(store, filters = {}) {
    store.commit('UPDATE_SAP_LEAVE_OF_ABSENCE_EXTENSION_PROCESSES', [])
    const filtersArr = joinFiltersSAP(filters)
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BASE}/es/api/v2/contractManager/leave.sapLeaveOfAbsenceExtensionList${queryString}`,
        )
        .then((res) => {
          store.commit(
            'UPDATE_SAP_LEAVE_OF_ABSENCE_EXTENSION_PROCESSES',
            res.data.data,
          )
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // SAP LEAVE NUMBERS
  // GET
  async fetchSapLeaveRecords(store, { payload, txtWanted }) {
    let url = ''
    switch (txtWanted) {
      case 'numbers':
        store.commit('UPDATE_SAP_LEAVE_RECORDS', [])
        url = 'downloadSapNumbers'
        break
      case 'cats':
        store.commit('UPDATE_SAP_LEAVE_CATS', [])
        url = 'downloadCats'
        break
      default:
        store.commit('UPDATE_SAP_LEAVE_RECORDS', [])
        url = 'downloadList'
    }
    const queryString =
      payload.processesUuids.length > 0
        ? `?filter[leaveProcessesUuid]=${payload.processesUuids}&filter[notShowExecutedAndMarkedRehireability]=${payload.notShowExecutedAndMarkedRehireability}`
        : `?filter[notShowExecutedAndMarkedRehireability]=${payload.notShowExecutedAndMarkedRehireability}`
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/leaveProcesses.common/${url}${queryString}`,
      )
      switch (txtWanted) {
        case 'numbers':
          store.commit('UPDATE_SAP_LEAVE_RECORDS', res.data)
          break
        case 'cats':
          store.commit('UPDATE_SAP_LEAVE_CATS', res.data.catsData)
          break
        default:
          store.commit('UPDATE_SAP_LEAVE_RECORDS', res.data)
      }
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/leaveProcesses.common/${url}${queryString}`,
      )
    }
  },
  // SAP NUMBERS TXT LEAVE OF ABSENCE EXTENSION
  // GET
  async fetchSapLeaveOfAbsenceExtensionRecords(store, { payload }) {
    const queryString = `?filter[leaveOfAbsenceExtensionProcessesUuid]=${payload.processesUuids}`
    try {
      store.commit('UPDATE_SAP_LEAVE_OF_ABSENCE_EXTENSION_RECORDS', [])
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/leave.downloadSapLeaveOfAbsenceExtension${queryString}`,
      )
      store.commit('UPDATE_SAP_LEAVE_OF_ABSENCE_EXTENSION_RECORDS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contractManager/leave.downloadSapLeaveOfAbsenceExtension${queryString}`,
      )
    }
  },
  // NOT REHIREABLE REASONS
  async fetchNotRehireableReasons(store) {
    try {
      store.commit('UPDATE_NOT_REHIREABLE_REASONS', {})
      const res = await axios.get(`${BASE}/es/api/v2/notRehireableReasons/all`)
      store.commit('UPDATE_NOT_REHIREABLE_REASONS', res.data.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /es/api/v2/notRehireableReasons/all.',
      )
    }
  },
  // ///////
  // POSTS
  // //////

  // NEW LEAVE
  // POST
  async startLeaveProcess(store, { payload, leaveReasonName }) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.startLeaveProcess.${leaveReasonName}`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.startLeaveProcess.${leaveReasonName}.`,
      )
    }
  },

  // NEW LEAVE OF ABSENCE
  // POST
  async startLeaveOfAbsenceProcess(store, { payload, leaveReasonName }) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.startLeaveOfAbsence.${leaveReasonName}`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.startLeaveOfAbsence.${leaveReasonName}.`,
      )
    }
  },

  // UPDATE REHIREABILITY
  // POST
  async updateRehireability(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.updateRehireability`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.updateRehireability.`,
      )
    }
  },
  // UPDATE HR MANAGEMENT
  // POST
  async updateHRManagement(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.updateHRManagement`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.updateHRManagement.`,
      )
    }
  },
  // UPDATE HR MANAGEMENT
  // POST
  async updateSapLeaveOfAbsenceExtension(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.updateSapLeaveOfAbsenceExtension`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.updateSapLeaveOfAbsenceExtension`,
      )
    }
  },
  // CANCEL LEAVE PROCESS
  // POST
  async cancelLeaveProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.cancelLeaveProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.cancelLeaveProcess.`,
      )
    }
  },
  // CANCEL LEAVE OF ABSENCE PROCESS
  // POST
  async cancelLeaveOfAbsenceProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.cancelLeaveOfAbsenceProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.cancelLeaveOfAbsenceProcess.`,
      )
    }
  },
  // CANCEL LEAVE OF ABSENCE PROCESS
  // POST
  async cancelLeaveOfAbsenceExtensionProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.cancelLeaveOfAbsenceExtensionProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.cancelLeaveOfAbsenceExtensionProcess.`,
      )
    }
  },
  // EXTEND LEAVE OF ABSENCE
  // POST
  async extendLeaveOfAbsenceAction(store, payload) {
    const { command } = payload
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.extendLeaveOfAbsence`,
        command,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.extendLeaveOfAbsence.`,
      )
    }
  },
}

function joinFilters(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    }
  })
  return filtersArr
}

function joinFiltersSAP(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    filtersArr.push(`filter[${key}]=${filters[key]}`)
  })
  return filtersArr
}
