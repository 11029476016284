<template lang="">
  <svg
    id="_24x24_On_Light_Support"
    width="35px"
    height="35px"
    viewBox="0 0 24 24"
    data-name="24x24/On Light/Support"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect id="view-box" width="24" height="24" fill="none" />
    <path
      id="Shape"
      d="M8,17.751a2.749,2.749,0,0,1,5.127-1.382C15.217,15.447,16,14,16,11.25v-3c0-3.992-2.251-6.75-5.75-6.75S4.5,4.259,4.5,8.25v3.5a.751.751,0,0,1-.75.75h-1A2.753,2.753,0,0,1,0,9.751v-1A2.754,2.754,0,0,1,2.75,6h.478c.757-3.571,3.348-6,7.022-6s6.264,2.429,7.021,6h.478a2.754,2.754,0,0,1,2.75,2.75v1a2.753,2.753,0,0,1-2.75,2.75H17.44A5.85,5.85,0,0,1,13.5,17.84,2.75,2.75,0,0,1,8,17.751Zm1.5,0a1.25,1.25,0,1,0,1.25-1.25A1.251,1.251,0,0,0,9.5,17.751Zm8-6.75h.249A1.251,1.251,0,0,0,19,9.751v-1A1.251,1.251,0,0,0,17.75,7.5H17.5Zm-16-2.25v1A1.251,1.251,0,0,0,2.75,11H3V7.5H2.75A1.251,1.251,0,0,0,1.5,8.751Z"
      transform="translate(1.75 2.25)"
      fill="#ffffff"
    />
  </svg>
</template>
<script>
export default {
  name: 'EplanningSupportIcon',
}
</script>
