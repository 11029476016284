import axios from 'axios'
import i18n from '@src/locale/index'
import store from '@state/store'
import router from '@src/router'
import _ from 'lodash'
import { ElNotification } from 'element-plus'

const { t } = i18n.global

// If authorization header was lost, it is regenerated if possible
if (
  axios &&
  Object.prototype.hasOwnProperty.call(axios, 'defaults') &&
  Object.prototype.hasOwnProperty.call(axios.defaults, 'headers') &&
  !axios.defaults.headers.common.Authorization &&
  localStorage.getItem('auth.token')
) {
  if (localStorage.getItem('auth.token') === 'undefined') {
    localStorage.clear()
  } else {
    const token = JSON.parse(localStorage.getItem('auth.token'))
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_PUBLIC_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
})

let subscribers = []
let isRefreshingToken = false

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      Object.prototype.hasOwnProperty.call(response.data, 'success') &&
      !response.data.success
    ) {
      response.data.exceptions.map((e) => {
        if (
          (e.translationCode === 'PLANNING_RULE_NOT_FOUND' ||
            e.translationCode ===
              'CONTRACT_TYPE_NOT_COMPATIBLE_HOUR_TYPOLOGY' ||
            e.translationCode ===
              'CONTRACT_TYPE_NOT_COMPATIBLE_HOUR_TYPOLOGY_COMPLEMENTARY_PACT_NOT_SIGNED' ||
            e.translationCode ===
              'CONTRACT_TYPE_NOT_COMPATIBLE_HOUR_TYPOLOGY_COMPLEMENTARY_PACT_NOT_FOR_HOURS' ||
            e.translationCode === 'COMPLEMENTARY_PACT_PROCESS_NOT_FOUND') &&
          !_.isEmpty(e.values)
        ) {
          return showPlanningRuleExceptionError(e.translationCode, e.values)
        } else if (
          e.translationCode === 'HOUR_TYPOLOGY_NOT_COMPATIBLE' &&
          !_.isEmpty(e.values)
        ) {
          return showNotCompatibleExceptionError(e.translationCode, e.values)
        } else if (
          e.translationCode === 'MISSING_EMAIL_ADDRESS' &&
          !_.isEmpty(e.values)
        ) {
          return showMissingEmailExceptionError(e.translationCode, e.values)
        } else if (
          e.translationCode === 'EMAIL_NOT_SENT' &&
          !_.isEmpty(e.values)
        ) {
          return showMissingEmailExceptionError(e.translationCode, e.values)
        } else {
          return showExceptionError(e.translationCode)
        }
      })
    }
    return response
  },

  (error) => {
    const code = error.code || null
    if (code === 'ECONNABORTED') {
      ElNotification({
        title: t('message.timeout'),
        message: t('message.timeoutMessage'),
        type: 'error',
        offset: 50,
        duration: 9000,
      })
    }
    const config = error.config
    const status = error.response.status
    const exceptions = error.response.data.exceptions

    const originalRequest = config
    if (status === 401) {
      // Expired authentication token error should be transparent for UI. Won't be showed notification
      if (exceptions[0].translationCode !== 'EXPIRED_AUTHENTICATION_TOKEN') {
        showExceptionError(exceptions[0].translationCode)
      }
      if (exceptions[0].translationCode === 'EXPIRED_AUTHENTICATION_TOKEN') {
        if (!isRefreshingToken) {
          isRefreshingToken = true
          store
            .dispatch('auth/sendRefreshToken', {
              refreshToken: JSON.parse(
                window.localStorage.getItem('auth.refreshToken'),
              ),
            })
            .then((res) => {
              isRefreshingToken = false
              onRefreshed(null, res.data.accessToken)
              subscribers = []
            })
            .catch((error) => {
              router.push({ name: 'logout' })
              onRefreshed(error, null)
            })
        }
        return new Promise((resolve, reject) => {
          subscribeTokenRefresh((error, token) => {
            if (token) {
              axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
              axios.defaults.headers.common.Authorization = `Bearer ${token}`
              originalRequest.headers.Authorization = `Bearer ${token}`
              resolve(axios(originalRequest))
            } else reject(error)
          })
        })
      } else {
        router.push({ name: 'logout' })
      }
    } else {
      showExceptionError(exceptions[0].translationCode)
      if (status === 400 && config.url.includes('profile')) {
        store.dispatch('auth/logOut').then(() => {
          router.push('/logout')
        })
      }
    }
    return Promise.reject(error)
  },
)

const subscribeTokenRefresh = (callback) => {
  subscribers.push(callback)
}

const onRefreshed = (error, token = null) => {
  subscribers.map((subscriber) => {
    return subscriber(error, token)
  })
}

const showExceptionError = (translationCode) => {
  ElNotification({
    title: t('message.errorTitle'),
    message: t(`exception.${translationCode}`),
    type: 'error',
    offset: 50,
  })
}

const showPlanningRuleExceptionError = (translationCode, values) => {
  ElNotification({
    title: t('message.errorTitle'),
    message:
      t(`exception.${translationCode}`) +
      ' ' +
      t(`message.day${values.dayOfWeek}`) +
      ' ' +
      values.day +
      ' ' +
      values.fromHour +
      ' - ' +
      values.toHour,
    type: 'error',
    offset: 50,
    duration: 9000,
  })
}

const showNotCompatibleExceptionError = (translationCode, values) => {
  ElNotification({
    title: t('message.errorTitle'),
    message:
      t(`exception.${translationCode}`) +
      ' ' +
      t(`message.day${values.dayOfWeek}`) +
      ' ' +
      values.day +
      ' (' +
      values.firstHourTypology +
      ' con ' +
      values.secondHourTypologyUuid +
      ')',
    type: 'error',
    offset: 50,
    duration: 9000,
  })
}

const showMissingEmailExceptionError = (translationCode, values) => {
  ElNotification({
    title: t('message.errorTitle'),
    message: values.collaboratorName + ' ' + t(`exception.${translationCode}`),
    type: 'error',
    offset: 50,
    duration: 9000,
  })
}

export default axiosInstance
