import { aggregates, urlQueryStringBuilder } from '@utils/constantHelpers'
import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    openProcesses: [],
    openProcess: null,
    identification: {
      identificationNumber: '',
      documentType: '',
    },
    agreementSalary: {},
    curriculumOrigins: [],
    resultExceptions: [],
    pendingSapHiringProcesses: [],
    sapHiringRecords: [],
    pendingSapContractExtensionProcesses: [],
    verificationDocumentTokenData: {
      token: '',
      identificationNumber: '',
    },
    endOfContractDocument: null,
    notPassedTrialDocument: null,
    signaturePendingContractCollaborator: null,
    hireableWindows: [],
    hireableWindowsItem: {},
    windows90Days: [],
    windows90DaysItem: null,
    commercialOperation: [],
    commercialOperationItem: null,
    collaboratorsPerContractType: null,
    pendingToSign: null,
    callingCDIFDProcesses: null,
    isLoadingCheckId: false,
    conversionCDI90DaysProcesses: null,
    conversionCDI90DaysProcess: null,
    applicableWindow90Days: null,
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_OPEN_PROCESS(state, openProcess) {
    if ({}.hasOwnProperty.call(openProcess, 'exceptions')) {
      state.openProcess = null
    } else {
      state.openProcess = openProcess
    }
  },
  UPDATE_OPEN_PROCESSES(state, openProcesses) {
    state.openProcesses = openProcesses
  },
  UPDATE_CONTRACT_BASE_64(state, docName) {
    state.docName = docName
  },
  UPDATE_CONTRACT_BY_PROCESS_BASE_64(state, pdfName) {
    state.pdfName = pdfName
  },
  UPDATE_AGREED_SALARY(state, agreementSalary) {
    state.agreementSalary = agreementSalary
  },
  UPDATE_CURRICULUM_ORIGINS(state, curriculumOrigins) {
    state.curriculumOrigins = curriculumOrigins
  },
  UPDATE_IDENTIFICATION_NUMBER(state, identificationNumber) {
    state.identification.identificationNumber = identificationNumber
  },
  SET_LOADING_CHECK_ID(state, value) {
    state.isLoadingCheckId = value
  },
  UPDATE_DOCUMENT_TYPE(state, documentType) {
    state.identification.documentType = documentType
  },
  UPDATE_PENDING_SAP_HIRING_PROCESSES(state, pendingSapHiringProcesses) {
    state.pendingSapHiringProcesses = pendingSapHiringProcesses
  },
  UPDATE_SAP_HIRING_RECORDS(state, sapHiringRecords) {
    state.sapHiringRecords = sapHiringRecords
  },
  UPDATE_PENDING_SAP_CONTRACT_EXTENSION_PROCESSES(
    state,
    pendingSapContractExtensionProcesses,
  ) {
    state.pendingSapContractExtensionProcesses =
      pendingSapContractExtensionProcesses
  },
  UPDATE_VERIFICATION_DOCUMENT_TOKEN_DATA(
    state,
    { identificationNumber, token },
  ) {
    state.verificationDocumentTokenData.identificationNumber =
      identificationNumber
    state.verificationDocumentTokenData.token = token
  },
  UPDATE_END_OF_CONTRACT_DOCUMENT(state, endOfContractDocument) {
    state.endOfContractDocument = endOfContractDocument
  },
  UPDATE_NOT_PASSED_TRIAL_DOCUMENT(state, notPassedTrialDocument) {
    state.notPassedTrialDocument = notPassedTrialDocument
  },
  RESET_CONTRACT_MANAGER_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_PENDING_CONTRACTS_PROCESSES_COLLABORATOR(
    state,
    signaturePendingContractCollaborator,
  ) {
    state.signaturePendingContractCollaborator =
      signaturePendingContractCollaborator
  },
  UPDATE_HIREABLE_WINDOWS(state, hireableWindows) {
    state.hireableWindows = hireableWindows
  },
  UPDATE_HIREABLE_WINDOW_ITEM(state, hireableWindowsItem) {
    state.hireableWindowsItem = hireableWindowsItem
  },
  UPDATE_WINDOWS_90_DAYS(state, windows90Days) {
    state.windows90Days = windows90Days
  },
  UPDATE_WINDOWS_90_DAYS_ITEM(state, windows90DaysItem) {
    state.windows90DaysItem = windows90DaysItem
  },
  UPDATE_COMMERCIAL_OPERATION(state, commercialOperation) {
    state.commercialOperation = commercialOperation
  },
  UPDATE_COMMERCIAL_OPERATION_ITEM(state, commercialOperationItem) {
    state.commercialOperationItem = commercialOperationItem
  },
  UPDATE_COLLABORATORS_PER_CONTRACT_TYPE(state, collaboratorsPerContractType) {
    state.collaboratorsPerContractType = collaboratorsPerContractType
  },
  UPDATE_PENDING_TO_SIGN(state, pendingToSign) {
    state.pendingToSign = pendingToSign
  },
  UPDATE_CALLING_CDIFD_PROCESSES(state, callingCDIFDProcesses) {
    state.callingCDIFDProcesses = callingCDIFDProcesses
  },
  UPDATE_CONVERSION_CDI90DAYS_PROCESSES(state, conversionCDI90DaysProcesses) {
    state.conversionCDI90DaysProcesses = conversionCDI90DaysProcesses
  },
  UPDATE_CONVERSION_CDI90DAYS_PROCESS(state, conversionCDI90DaysProcess) {
    state.conversionCDI90DaysProcess = conversionCDI90DaysProcess
  },
  UPDATE_APPLICABLE_WINDOWS_90_DAYS(state, applicableWindow90Days) {
    state.applicableWindow90Days = applicableWindow90Days
  },
}

export const actions = {
  // OPEN PROCESSES
  // GET
  async fetchOpenProcess(store, options = {}) {
    // store.commit('UPDATE_OPEN_PROCESS', null)
    let url = `${BASE}/es/api/v2/contractProcesses.common/${options.processUuid}`
    if (options.personnelDivision) {
      // Review
      url = `${BASE}/es/api/v2/sites/${options.personnelDivision}/contractProcesses.common/${options.processUuid}`
    }
    if (options.withIndex) {
      url = `${url}?withIndex=true`
    }
    if (options.withProcessRoadmap) {
      url = `${url}?withProcessRoadmap=true`
    }
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_OPEN_PROCESS', res.data)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error.message}`)
    }
  },
  // CLEAR OPEN PROCESSES
  async resetOpenProcesses(store) {
    store.commit('UPDATE_OPEN_PROCESSES', [])
  },
  // OPEN PROCESSES
  // GET
  async fetchOpenProcesses(store, payload = {}) {
    store.commit('UPDATE_OPEN_PROCESSES', [])
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/contractProcesses.common${urlQueryStringBuilder(
          payload,
        )}`,
      )
      store.commit('UPDATE_OPEN_PROCESSES', res.data)
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in es/api/v2/contractProcesses.common${urlQueryStringBuilder(
          payload,
        )}.`,
      )
    }
  },
  // OPEN PROCESSES OF SITE
  // GET
  async fetchSiteOpenProcesses(store, payload = {}) {
    store.commit('UPDATE_OPEN_PROCESSES', {})
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/sites/${
          payload.personnelDivision
        }/contractProcesses.common${urlQueryStringBuilder(payload)}`,
      )
      store.commit('UPDATE_OPEN_PROCESSES', res.data)
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in es/api/v2/sites/${
          payload.personnelDivision
        }/contractProcesses.common${urlQueryStringBuilder(payload)}.`,
      )
    }
  },
  // CONTRACT PDF GENERATION
  // GET
  async fetchContractInBase64(store, options = {}) {
    try {
      store.commit('UPDATE_CONTRACT_BASE_64', {})
      const URL = `${BASE}/es/api/v2/contracts/fetchPdfBase64/${options.processUuid}`
      const res = await axios.get(URL)
      store.commit('UPDATE_CONTRACT_BASE_64', res.data.name)
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contracts/fetchPdfBase64/${options.processUuid}`,
      )
    }
  },
  async fetchContractByProcessInBase64(store, options = {}) {
    try {
      store.commit('UPDATE_CONTRACT_BY_PROCESS_BASE_64', {})
      const URL = `${BASE}/es/api/v2/contractProcesses.fetchPdfBase64/${options.processUuid}`
      const res = await axios.get(URL)
      store.commit('UPDATE_CONTRACT_BY_PROCESS_BASE_64', res.data.name)
      return res
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contractProcesses.fetchPdfBase64/${options.processUuid}`,
      )
    }
  },
  // AGREED SALARY
  // GET
  async fetchAgreementSalary(store, code) {
    try {
      store.commit('UPDATE_AGREED_SALARY', {})
      const res = await axios.get(`${BASE}/api/v2/parameters/code/${code}`)
      store.commit('UPDATE_AGREED_SALARY', res.data)
    } catch (error) {
      throw new Error(`API Error occurred at /api/v2/parameters/code/${code}`)
    }
  },
  // GET CV SOURCES
  async fetchCurriculumOrigin(store) {
    try {
      const res = await axios.get(`${BASE}/es/api/v2/curriculumOrigins`)
      store.commit('UPDATE_CURRICULUM_ORIGINS', res.data)
    } catch (error) {
      throw new Error('API Error occurred in /api/v2/curriculumOrigins/.')
    }
  },
  // PENDING SAP HIRING PROCESSES
  // GET
  async fetchPendingSapHiringProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_SAP_HIRING_PROCESSES', [])
    let queryString = ''
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((key) => {
      if (filters[key]) {
        if (key === 'dateInterval') {
          const newKey = `startDateInterval=${filters[key][0]}&endDateInterval=${filters[key][1]}`
          return (queryString += `&${newKey}`)
        } else return (queryString += `&${key}=${filters[key]}`)
      }
    })
    try {
      const response = await axios.get(
        `${BASE}/es/api/v2/contractManager/hiring.updateSap?${queryString}`,
      )
      store.commit('UPDATE_PENDING_SAP_HIRING_PROCESSES', response.data)

      return response.data
    } catch (error) {
      throw new Error(
        'API Error occurred in es/api/v2/contractManager/hiring.updateSap' +
          queryString,
      )
    }
  },
  // SAP HIRING RECORDS
  // GET
  async fetchSapHiringRecords(store, filters = {}) {
    store.commit('UPDATE_SAP_HIRING_RECORDS', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        return filtersArr.push(`${key}=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/hiring.sapRecords${queryString}`, // it is necessary to attach filters date, etc to URL
      )
      store.commit('UPDATE_SAP_HIRING_RECORDS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in es/api/v2/contractManager/hiring.sapRecords${queryString}`,
      )
    }
  },
  // PENDING SAP CONTRACT EXTENSION PROCESSES
  // GET
  async fetchPendingSapContractExtensionProcesses(store, filters = {}) {
    store.commit('UPDATE_PENDING_SAP_CONTRACT_EXTENSION_PROCESSES', [])
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/extension.updateSapProcess${queryString}`,
      )
      store.commit('UPDATE_PENDING_SAP_CONTRACT_EXTENSION_PROCESSES', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /es/api/v2/contractManager/extension.updateSapProcess' +
          queryString,
      )
    }
  },
  // SAP CONTRACT EXTENSION RECORDS
  // GET
  async fetchSapContractExtensionRecords(store, payload) {
    const queryString =
      payload.processesUuids.length > 0
        ? `?filter[extensionProcessesUuid]=${payload.processesUuids}`
        : ''
    try {
      return await axios.get(
        `${BASE}/es/api/v2/contractManager/extension.updateSapProcess/downloadList${queryString}`,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/extension.updateSapProcess/downloadList${queryString}`,
      )
    }
  },
  // END OF CONTRACT DOCUMENT
  // GET
  async fetchEndOfContractDocumentAction(store, collaboratorUuid) {
    try {
      store.commit('UPDATE_END_OF_CONTRACT_DOCUMENT', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/contracts/completion.notice.document/collaborator/${collaboratorUuid}`,
      )
      store.commit('UPDATE_END_OF_CONTRACT_DOCUMENT', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contracts/completion.notice.document/collaborator/${collaboratorUuid}`,
      )
    }
  },
  // NOT PASSED TRIAL DOCUMENT
  // GET
  async fetchNotPassedTrialDocumentAction(store, collaboratorUuid) {
    try {
      store.commit('UPDATE_NOT_PASSED_TRIAL_DOCUMENT', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/contracts/not.passed.trial.notice.document/collaborator/${collaboratorUuid}`,
      )
      store.commit('UPDATE_NOT_PASSED_TRIAL_DOCUMENT', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contracts/not.passed.trial.notice.document/collaborator/${collaboratorUuid}`,
      )
    }
  },

  // //////
  // POST
  // //////

  // HIRING
  // CHECK ID before start Hiring
  async checkId({ commit }, payload) {
    commit('SET_LOADING_CHECK_ID', true)
    try {
      const response = await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.checkId`,
        payload,
      )
      commit(
        'UPDATE_IDENTIFICATION_NUMBER',
        payload.identificationCountryDto.personalIdentificationNumber,
      )
      commit(
        'UPDATE_DOCUMENT_TYPE',
        payload.identificationCountryDto.identificationDocumentType,
      )
      return response
    } catch (e) {
      throw Error('API Error occurred in api/checkId.')
    } finally {
      commit('SET_LOADING_CHECK_ID', false)
    }
  },
  // POST ID + TOKEN
  async postIdToken({ commit }, payload) {
    try {
      const response = await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.verifyIdentificationDocumentAndToken`,
        payload,
      )
      if (response.data.success) {
        commit('UPDATE_VERIFICATION_DOCUMENT_TOKEN_DATA', {
          identificationNumber:
            payload.verifyDocumentTokenCountryDto.identificationNumber,
          token: payload.verifyDocumentTokenCountryDto.token,
        })
        commit(
          'collaborator/UPDATE_COLLABORATOR',
          response.data.aggregates.collaborator,
          { root: true },
        )
      }
      return response
    } catch (e) {
      throw Error(
        `API Error occurred in /api/v2/contractManager/hiring.verifyIdentificationDocumentAndToken.`,
      )
    }
  },

  // NEW HIRING
  async startHiringProcessAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.startHiringProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at api/v2/contractManager/hiring.startHiringProcess.`,
      )
    }
  },

  // RECTIFY HIRING PROCESS
  async editRectifyHiringProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.rectify`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at api/v2/contractManager/hiring.startHiringProcess.`,
      )
    }
  },

  // EDIT HIRING PROCESS
  async editHiringProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.edit`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at api/v2/contractManager/hiring.edit.`)
    }
  },

  // VALIDATE PROCESS
  async validateProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/common.validateProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/common.validateProcess.`,
      )
    }
  },

  // CANCEL HIRING CONTRACT PROCESS
  async cancelContractProcess(store, payload) {
    const aggregate =
      aggregates[payload.processType] === undefined
        ? 'hiring'
        : aggregates[payload.processType]

    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/${aggregate}.cancelProcess`,
        payload.command,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/${aggregate}.cancelProcess.`,
      )
    }
  },

  // sendEmail to Collaborator with link to enter personal in first steps of hiring contract process
  async sendEmailCollaboratorStart(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.sendEmailCollaboratorToken`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/hiring.sendEmailCollaboratorToken.`,
      )
    }
  },

  // POST PERSONAL AND ADDRESS DATA, HIRING PROCESS
  async insertCollaboratorData(store, command) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.insertPersonalData`,
        command,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/hiring.insertPersonalData`,
      )
    }
  },
  // POST ASSIGN SAP
  async assignSap(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.assignIndividualSap`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/hiring.assignIndividualSap`,
      )
    }
  },
  // POST ASSIGN MULTIPLE SAP
  async assignMultipleSap(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.assignMultipleSap`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/hiring.assignMultipleSap`,
      )
    }
  },

  // SIGN PROCESS IN PAPER
  async signOnPaperAction(store, payload) {
    let path
    switch (payload.processType) {
      case 'HIRING_CONTRACT_PROCESS_COUNTRY':
        path = 'hiring'
        break
      case 'CONVERSION_CONTRACT_PROCESS_COUNTRY':
        path = 'conversion'
        break
      case 'REHIRING_CONTRACT_PROCESS_COUNTRY':
      case 'REHIRING_FROM_LEAVE_OF_ABSENCE_PROCESS_COUNTRY':
        path = 'rehiring'
        break
      case 'EXTENSION_CONTRACT_PROCESS_COUNTRY':
        path = 'extension'
        break
      case 'CONVERSION_CONTRACT_PROCESS_CDI_WEEKEND_90_DAYS':
        path = 'conversionCDIWeekend90Days'
        break
    }

    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/${path}.signOnPaper`,
        payload.command,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contractManager/${path}.signOnPaper`,
      )
    }
  },

  // REHIRING
  // START REHIRING PROCESS

  async startRehiringProcessAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.startRehiringProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/hiring.startRehiringProcess.`,
      )
    }
  },

  // LEAVE OF ABSENCE RE-ENTRY
  async leaveOfAbsenceReEntryAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.startRehiringLeaveOfAbsence`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.startRehiringLeaveOfAbsence.`,
      )
    }
  },
  // LEAVE OF ABSENCE RE-ENTRY RECTIFICATION
  async rectifyRehiringFromLeaveOfAbsenceAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/leave.rectifyRehiringFromLeaveOfAbsence `,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/leave.rectifyRehiringFromLeaveOfAbsence .`,
      )
    }
  },

  // CONTRACT EXTENSION
  // POST
  async extendContractAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/extension.startExtensionProcess`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error ocurred in /es/api/v2/contractManager/extension.startExtensionProcess: ${error}`,
      )
    }
  },
  async editExtensionProcessAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/extension.edit`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error ocurred in /es/api/v2/contractManager/extension.edit: ${error}`,
      )
    }
  },
  // SEND CONTRACT DOCUMENT
  // POST
  async sendProcessAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/common.sendContract`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error ocurred in /es/api/v2/contractManager/common.sendContract: ${error}`,
      )
    }
  },

  // UPDATE MANAGED HIRING PROCESSES
  // POST
  async updateManagedHiringProcesses(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/hiring.updateSap.management`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/hiring.updateSap.management.`,
      )
    }
  },
  // SAP CONTRACT EXTENSION RECORDS
  // POST
  async updateManagedContractExtensionProcesses(store, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/extension.updateSapProcess.management`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in /es/api/v2/contractManager/extension.updateSapProcess.management`,
      )
    }
  },
  // SIGNATURE PENDING CONTRACT PROCESSES COLLABORATOR
  // GET
  async fetchSignaturePendingContractCollaborator(store) {
    try {
      store.commit('UPDATE_PENDING_CONTRACTS_PROCESSES_COLLABORATOR', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/contractManager/collaborator.signaturePendingContractProcesses`,
      )
      store.commit('UPDATE_PENDING_CONTRACTS_PROCESSES_COLLABORATOR', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/contractManager/collaborator.signaturePendingContractProcesses`,
      )
    }
  },
  // GET HIREABLE WINDOWS LIST
  // GET
  async fetchHireableWindows(store) {
    try {
      // store.commit('UPDATE_HIREABLE_WINDOWS', null)
      const res = await axios.get(`${BASE}/es/api/v2/hireableWindow`)
      store.commit('UPDATE_HIREABLE_WINDOWS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${BASE}/es/api/v2/hireableWindow`)
    }
  },
  // HIREABLE WINDOW CREATE
  // POST
  async hireableWindowCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/hireableWindow/create`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/hireableWindow/create`)
    }
  },
  // HIREABLE WINDOW
  // GET
  async fetchHireableWindowItemAction(store, uuid) {
    if (state.hireableWindows && state.hireableWindows.length > 0) {
      try {
        const item = state.hireableWindows.filter(
          (item) => item.uuid === uuid,
        )[0]
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        item.startDate = `${item.startMonth}-${item.startDay}-${currentYear}`
        item.endDate = `${item.endMonth}-${item.endDay}-${currentYear}`
        if (item.startDate > item.endDate) {
          const nextYear = currentYear + 1
          item.endDate = `${item.endMonth}-${item.endDay}-${nextYear}`
        }
        item.extensionEndDate = calcDate(item.endDate, item.maxExtensionDays)
        item.callingStartDate = calcDate(item.startDate, item.seasonStartDays)
        item.callingEndDate = calcDate(
          item.callingStartDate,
          item.maxCallableDays,
        )
        store.commit('UPDATE_HIREABLE_WINDOW_ITEM', item)
      } catch (error) {
        throw new Error('API Error occurred in fetchHireableWindowItemAction')
      }
    } else {
      try {
        const res = await axios.get(
          `${BASE}/es/api/v2/hireableWindow?filter[uuid]=${uuid}`,
        )
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        res.data[0].startDate = `${res.data[0].startMonth}-${res.data[0].startDay}-${currentYear}`
        res.data[0].endDate = `${res.data[0].endMonth}-${res.data[0].endDay}-${currentYear}`
        if (res.data[0].startDate > res.data[0].endDate) {
          const nextYear = currentYear + 1
          res.data[0].endDate = `${res.data[0].endMonth}-${res.data[0].endDay}-${nextYear}`
        }
        res.data[0].extensionEndDate = calcDate(
          res.data[0].endDate,
          res.data[0].maxExtensionDays,
        )
        res.data[0].callingStartDate = calcDate(
          res.data[0].startDate,
          res.data[0].seasonStartDays,
        )
        res.data[0].callingEndDate = calcDate(
          res.data[0].callingStartDate,
          res.data[0].maxCallableDays,
        )
        const item = res.data[0]
        store.commit('UPDATE_HIREABLE_WINDOW_ITEM', item)
      } catch (error) {
        throw new Error(
          `API Error occurred in ${BASE}/es/api/v2/hireableWindow?filter[uuid]`,
        )
      }
    }
  },
  // HIREABLE WINDOW UPDATE
  // PUT
  async hireableWindowUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/hireableWindow/update`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/hireableWindow/create`)
    }
  },
  // HOME RESPONSIBLE - GET COLLABORATORS PER CONTRACT TYPE
  // GET
  async fetchCollaboratorsPerContractType(store) {
    try {
      store.commit('UPDATE_COLLABORATORS_PER_CONTRACT_TYPE', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/collaboratorsPerContractType`,
      )
      store.commit('UPDATE_COLLABORATORS_PER_CONTRACT_TYPE', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /api/v2/collaboratorsPerContractType',
      )
    }
  },
  // HOME RESPONSIBLE - GET pendingToSign
  // GET
  async fetchPendingToSign(store) {
    try {
      store.commit('UPDATE_PENDING_TO_SIGN', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/contractProcesses.common/alert/pendingToSign`,
      )
      store.commit('UPDATE_PENDING_TO_SIGN', res.data)
    } catch (error) {
      throw new Error(
        'API Error occurred in /api/v2/contractProcesses.common/alert/pendingToSign',
      )
    }
  },
  // REACTIVATE COLLABORATOR
  // POST
  async reactivateCollaboratorAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/common.reactivateContract`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/common.reactivateContract`,
      )
    }
  },
  // FETCH CDI CALLING OPEN PROCESSES
  // GET
  async fetchCallDiscontinuousFixedProcess(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/contractManager/callDiscontinuousFixedProcess.list${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_CALLING_CDIFD_PROCESSES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // FETCH CONVERSION CDI PROCESSES
  // GET
  async fetchConversionCDIWeekendProcesses(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/contractManager/conversionCDIWeekend90Days${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_CONVERSION_CDI90DAYS_PROCESSES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // CONVERSION CDI PROCESSES
  // GET
  async fetchConversionCDIWeekendProcess(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/contractManager/conversionCDIWeekend90DaysByUuid${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_CONVERSION_CDI90DAYS_PROCESS', res.data)
      return res
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error.message}`)
    }
  },
  // START CALLING CDI FD PROCESS
  // POST
  async startCallingProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/call.startCallProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/call.startCallProcess`,
      )
    }
  },
  // UPDATE CALLING CDI FD PROCESS
  // POST
  async updateCallingProcessAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/call.updateCallProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/call.updateCallProcess`,
      )
    }
  },
  // CANCEL CALLING CDI FD PROCESS
  // POST
  async cancelCallingProcess({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/call.cancelCallProcess`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/call.cancelCallProcess`,
      )
    }
  },
  // CANCEL CONVERSION CDI 90 DAYS PROCESS
  // POST
  async cancelConversionCDI90DaysProcess({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/contractManager/conversionCDIWeekend90Days.cancel`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/contractManager/conversionCDIWeekend90Days.cancel`,
      )
    }
  },
  // FETCH (show) CDI CALLING 1 OPEN PROCESSES
  // GET
  async fetchCallDiscontinuousFixedProcessData(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/es/api/v2/contractManager/callDiscontinuousFixedProcess.show${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_CALLING_CDIFD_PROCESSES', res.data)
      return res
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
  // GET WINDOWS 90 DAYS LIST
  // GET
  async fetchWindows90Days(store) {
    try {
      const res = await axios.get(`${BASE}/es/api/v2/window90Days`)
      store.commit('UPDATE_WINDOWS_90_DAYS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${BASE}/es/api/v2/window90Days`)
    }
  },
  // WINDOWS 90 DAYS CREATE
  // POST
  async window90DaysCreateAction({ store }, payload) {
    try {
      return await axios.post(`${BASE}/es/api/v2/window90Days/create`, payload)
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/window90Days/create`)
    }
  },
  // WINDOWS 90 DAYS
  // GET
  async fetchWindow90DaysItemAction(store, uuid) {
    store.commit('UPDATE_WINDOWS_90_DAYS_ITEM', null)
    if (state.windows90Days && state.windows90Days.length > 0) {
      try {
        const item = state.windows90Days.filter((item) => item.uuid === uuid)[0]
        store.commit('UPDATE_WINDOWS_90_DAYS_ITEM', item)
      } catch (error) {
        throw new Error('API Error occurred in fetchWindow90DaysItemAction')
      }
    } else {
      try {
        const res = await axios.get(`${BASE}/es/api/v2/window90Days/${uuid}`)
        const item = res.data
        store.commit('UPDATE_WINDOWS_90_DAYS_ITEM', item)
      } catch (error) {
        throw new Error(
          `API Error occurred in ${BASE}/es/api/v2/window90Days/${uuid}`,
        )
      }
    }
  },
  // WINDOWS 90 DAYS UPDATE
  // PUT
  async window90DaysUpdateAction({ store }, payload) {
    try {
      return await axios.post(`${BASE}/es/api/v2/window90Days/update`, payload)
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/window90Days/update`)
    }
  },
  // DELETE WINDOWS 90 DAYS
  // POST
  async window90DaysDeleteAction({ store }, payload) {
    try {
      return await axios.post(`${BASE}/es/api/v2/window90Days/delete`, payload)
    } catch (e) {
      throw Error(`API Error occurred at /es/api/v2/window90Days/delete: ${e}`)
    }
  },

  async fetchApplicableWindow90DaysAction(store, filters) {
    try {
      store.commit('UPDATE_APPLICABLE_WINDOWS_90_DAYS', null)
      const res = await axios.get(
        `${BASE}/es/api/v2/window90Days/applicableWindowForSiteType` +
          urlQueryStringBuilder(filters),
      )
      const item = res.data
      store.commit(
        'UPDATE_APPLICABLE_WINDOWS_90_DAYS',
        Object.keys(item).length > 0 ? item : null,
      )
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/window90Days/applicableWindowForSiteType` +
          urlQueryStringBuilder(filters),
      )
    }
  },

  // COMMERCIAL OPERATION LIST
  // GET
  async fetchCommercialOperation(store, filters) {
    const { windowUuid } = filters
    try {
      const res = await axios.get(
        `${BASE}/es/api/v2/commercialOperation/byWindow/${windowUuid}`,
      )
      store.commit('UPDATE_COMMERCIAL_OPERATION', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/es/api/v2/commercialOperation/byWindow/${windowUuid}`,
      )
    }
  },
  // COMMERCIAL OPERATION CREATE
  // POST
  async commercialOperationCreateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/commercialOperation/commercialOperation.create`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/commercialOperation/commercialOperation.create`,
      )
    }
  },
  // COMMERCIAL OPERATION
  // GET
  async fetchCommercialOperationItemAction(store, uuid) {
    store.commit('UPDATE_COMMERCIAL_OPERATION_ITEM', null)
    if (state.commercialOperation && state.commercialOperation.length > 0) {
      try {
        const item = state.commercialOperation.filter(
          (item) => item.uuid === uuid,
        )[0]
        store.commit('UPDATE_COMMERCIAL_OPERATION_ITEM', item)
      } catch (error) {
        throw new Error(
          'API Error occurred in fetchCommercialOperationItemAction',
        )
      }
    } else {
      try {
        const res = await axios.get(
          `${BASE}/es/api/v2/commercialOperation/${uuid}`,
        )
        const item = res.data
        store.commit('UPDATE_COMMERCIAL_OPERATION_ITEM', item)
      } catch (error) {
        throw new Error(
          `API Error occurred in ${BASE}/es/api/v2/commercialOperation/commercialOperation/${uuid}`,
        )
      }
    }
  },
  // COMMERCIAL OPERATION UPDATE
  // PUT
  async commercialOperationUpdateAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/commercialOperation/commercialOperation.edit`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/commercialOperation/commercialOperation.edit`,
      )
    }
  },

  // DELETE COMMERCIAL OPERATION
  // POST
  async commercialOperationDeleteAction({ store }, payload) {
    try {
      return await axios.post(
        `${BASE}/es/api/v2/commercialOperation/commercialOperation.delete`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /es/api/v2/commercialOperation/commercialOperation.delete: ${e}`,
      )
    }
  },
}

function calcDate(date, plus) {
  const [m, d, y] = date.split('-').map(Number)
  let year = y
  let month = m
  let day = d + plus
  while (day > 0) {
    const lastDayOfCurrentMonth = new Date(year, month, 0).getDate()
    if (day > lastDayOfCurrentMonth) {
      day -= lastDayOfCurrentMonth
      month++
      if (month > 12) {
        month = 1
        year++
      }
    } else {
      break
    }
  }
  return `${month}-${day}-${year}`
}
