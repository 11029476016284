export default function queryStringFilterBuilder(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    }
  })
  const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
  return queryString
}
